/**
 * Card styles for new UI/UX design system
 * https://projects.invisionapp.com/d/main/#/console/17020525/352851059/preview
 *
 *  <section class="card">
 *      <h1 class="card__heading">Widget Heading</h1>
 *      <div class="card__body">Widget Body</div>
 *  </section>
 */
 .card {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.14);
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    &--rounder {
        border-radius: 8px;
    }
    .card__heading {
        border-bottom: 1px solid #c1c1c1;
        margin-bottom: 0;
        padding: 10px 16px 7px;
    }
    .card__heading--large {
        padding-left: 20px;
        height: 48px;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    .card__body {
        overflow-y: auto;
        flex-direction: column;
    }
    &--with-centered-content {
        .card__body {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: center;
        }
    }
}