/*------------------------------------*\
    $SKINS
\*------------------------------------*/
/**
 * Helper classes for applying color styles.
 */

/**
 * Fill styles for SVG elements
 */
.fill-red 					{ fill: $red; }
.fill-red-light				{ fill: $red-light; }
.fill-green 				{ fill: $green; }
.fill-green-light 			{ fill: $green-light; }
.fill-orange 				{ fill: $orange; }
.fill-orange-light 			{ fill: $orange-light; }
.fill-yellow 				{ fill: $yellow; }
.fill-yellow-light 			{ fill: $yellow-light; }
.fill-black		 			{ fill: #000; }
.fill-white		 			{ fill: #fff; }
.fill-blue 					{ fill: $blue; }
.fill-blue-light			{ fill: $blue-light; }
.fill-grey			        { fill: #808080; }

.fill-shade-1-color			{ fill: $shade-1-color; }
.fill-shade-2-color			{ fill: $shade-2-color; }
.fill-shade-3-color			{ fill: $shade-3-color; }
.fill-shade-4-color			{ fill: $shade-4-color; }
.fill-brand-color			{ fill: $brand-color; }

.fill-equipment-container   { fill: $equipment-container-color; }
.fill-equipment-curtain     { fill: $equipment-curtain-color; }
.fill-equipment-euroliner   { fill: $equipment-euroliner-color; }
.fill-equipment-flatbed     { fill: $equipment-flatbed-color; }
.fill-equipment-low-loader  { fill: $equipment-low-loader-color; }
.fill-equipment-reefer      { fill: $equipment-reefer-color; }
.fill-equipment-multiple    { fill: $equipment-multiple-color; }
.fill-equipment-other       { fill: $equipment-other-color; }
.fill-equipment-power-only  { fill: $equipment-power-only-color; }
.fill-equipment-van         { fill: $equipment-van-color; }

/**
 * Background styles
 */
.bg-red 					{ background: $red; }
.bg-red-light				{ background: $red-light; }
.bg-green 					{ background: $green; }
.bg-green-light 			{ background: $green-light; }
.bg-orange 					{ background: $orange; }
.bg-orange-light 			{ background: $orange-light; }
.bg-yellow 					{ background: $yellow; }
.bg-yellow-light 			{ background: $yellow-light; }
.bg-black		 			{ background: #000; }
.bg-white		 			{ background: #fff; }
.bg-blue 					{ background: $blue; }
.bg-blue-light				{ background: $blue-light; }

.bg-shade-1-color			{ background: $shade-1-color; }
.bg-shade-2-color			{ background: $shade-2-color; }
.bg-shade-3-color			{ background: $shade-3-color; }
.bg-shade-4-color			{ background: $shade-4-color; }
.bg-brand-color				{ background: $brand-color; }

.bg-dark-grey        { background: $dark-grey; }
.bg-med-grey-1       { background: $med-grey-1; }
.bg-med-grey-2       { background: $med-grey-2; }
.bg-light-grey-1     { background: $light-grey-1; }
.bg-light-grey-2     { background: $light-grey-2; }

.bg-req-main                { background: $req-main-color; }
.bg-req-equipment           { background: $req-equipment-color; }
.bg-req-stop                { background: $req-stop-color; }

/**
 * Text styles
 */
.text-black					{ color: #000; }
.text-white                 { color: #fff; }
