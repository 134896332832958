/*------------------------------------*\
    $WIDTHS
\*------------------------------------*/
/**
 * Sizes in human readable format. These are used in conjunction with other
 * objects and abstractions found in inuit.css, most commonly the grid system
 * and faux flexbox.
 *
 * We have a mixin to generate our widths and their breakpoint-specific
 * variations.
 */

@mixin grid-setup($namespace: "") {
    /**
    * Whole
    */
    .#{$namespace}one-whole         { width: 100%; }


    /**
    * Halves
    */
    .#{$namespace}one-half          { width: 50%; }


    /**
    * Thirds
    */
    .#{$namespace}one-third         { width: 33.333%; }
    .#{$namespace}two-thirds        { width: 66.666%; }


    /**
    * Quarters
    */
    .#{$namespace}one-quarter       { width: 25%; }
    .#{$namespace}two-quarters      { @extend .#{$namespace}one-half; }
    .#{$namespace}three-quarters    { width: 75%; }


    /**
    * Fifths
    */
    .#{$namespace}one-fifth         { width: 20%; }
    .#{$namespace}two-fifths        { width: 40%; }
    .#{$namespace}three-fifths      { width: 60%; }
    .#{$namespace}four-fifths       { width: 80%; }


    /**
    * Sixths
    */
    .#{$namespace}one-sixth         { width: 16.666%; }
    .#{$namespace}two-sixths        { @extend .#{$namespace}one-third; }
    .#{$namespace}three-sixths      { @extend .#{$namespace}one-half; }
    .#{$namespace}four-sixths       { @extend .#{$namespace}two-thirds; }
    .#{$namespace}five-sixths       { width: 83.333%; }


    /**
    * Sevenths
    */
    .#{$namespace}one-seventh       { width: 14.286%; }
    .#{$namespace}two-sevenths      { width: 28.572%; }
    .#{$namespace}three-sevenths    { width: 42.858%; }
    .#{$namespace}four-sevenths     { width: 57.144%; }
    .#{$namespace}five-sevenths     { width: 71.43%; }
    .#{$namespace}six-sevenths      { width: 85.716%; }


    /**
    * Eighths
    */
    .#{$namespace}one-eighth        { width: 12.5%; }
    .#{$namespace}two-eighths       { @extend .#{$namespace}one-quarter; }
    .#{$namespace}three-eighths     { width: 37.5%; }
    .#{$namespace}four-eighths      { @extend .#{$namespace}one-half; }
    .#{$namespace}five-eighths      { width: 62.5%; }
    .#{$namespace}six-eighths       { @extend .#{$namespace}three-quarters; }
    .#{$namespace}seven-eighths     { width: 87.5%; }


    /**
    * Tenths
    */
    .#{$namespace}one-tenth         { width: 10%; }
    .#{$namespace}two-tenths        { @extend .#{$namespace}one-fifth; }
    .#{$namespace}three-tenths      { width: 30%; }
    .#{$namespace}four-tenths       { @extend .#{$namespace}two-fifths; }
    .#{$namespace}five-tenths       { @extend .#{$namespace}one-half; }
    .#{$namespace}six-tenths        { @extend .#{$namespace}three-fifths; }
    .#{$namespace}seven-tenths      { width: 70%; }
    .#{$namespace}eight-tenths      { @extend .#{$namespace}four-fifths; }
    .#{$namespace}nine-tenths       { width: 90%; }


    /**
    * Twelfths
    */
    .#{$namespace}one-twelfth       { width: 8.333%; }
    .#{$namespace}two-twelfths      { @extend .#{$namespace}one-sixth; }
    .#{$namespace}three-twelfths    { @extend .#{$namespace}one-quarter; }
    .#{$namespace}four-twelfths     { @extend .#{$namespace}one-third; }
    .#{$namespace}five-twelfths     { width: 41.666%; }
    .#{$namespace}six-twelfths      { @extend .#{$namespace}one-half; }
    .#{$namespace}seven-twelfths    { width: 58.333%; }
    .#{$namespace}eight-twelfths    { @extend .#{$namespace}two-thirds; }
    .#{$namespace}nine-twelfths     { @extend .#{$namespace}three-quarters; }
    .#{$namespace}ten-twelfths      { @extend .#{$namespace}five-sixths; }
    .#{$namespace}eleven-twelfths   { width: 91.666%; }


    /**
    * Fourteenths
    */
    .#{$namespace}one-fourteenth        { width: 7.142%; }
    .#{$namespace}two-fourteenths       { @extend .#{$namespace}one-seventh; }
    .#{$namespace}three-fourteenths     { width: 21.429%; }
    .#{$namespace}four-fourteenths      { @extend .#{$namespace}two-sevenths; }
    .#{$namespace}five-fourteenths      { width: 35.714%; }
    .#{$namespace}six-fourteenths       { @extend .#{$namespace}three-sevenths; }
    .#{$namespace}seven-fourteenths     { @extend .#{$namespace}one-half; }
    .#{$namespace}eight-fourteenths     { @extend .#{$namespace}four-sevenths; }
    .#{$namespace}nine-fourteenths      { width: 64.286%; }
    .#{$namespace}ten-fourteenths       { @extend .#{$namespace}five-sevenths; }
    .#{$namespace}eleven-fourteenths    { width: 78.571%; }
    .#{$namespace}twelve-fourteenths    {@extend .#{$namespace}six-sevenths; }
    .#{$namespace}thirteen-fourteenths  { width: 92.857%; }


    /**
    * Sixteenths
    */
    .#{$namespace}one-sixteenth       { width: 6.25%; }
    .#{$namespace}two-sixteenths      { @extend .#{$namespace}one-eighth; }
    .#{$namespace}three-sixteenths    { width: 18.75%; }
    .#{$namespace}four-sixteenths     { @extend .#{$namespace}one-quarter; }
    .#{$namespace}five-sixteenths     { width: 31.25%; }
    .#{$namespace}six-sixteenths      { @extend .#{$namespace}three-eighths; }
    .#{$namespace}seven-sixteenths    { width: 43.75%; }
    .#{$namespace}eight-sixteenths    { @extend .#{$namespace}one-half; }
    .#{$namespace}nine-sixteenths     { width: 56.25%; }
    .#{$namespace}ten-sixteenths      { @extend .#{$namespace}five-eighths; }
    .#{$namespace}eleven-sixteenths   { width: 68.75%; }
    .#{$namespace}twelve-sixteenths   { @extend .#{$namespace}three-quarters; }
    .#{$namespace}thirteen-sixteenths { width: 81.25%; }
    .#{$namespace}fourteen-sixteenths { @extend .#{$namespace}seven-eighths; }
    .#{$namespace}fifteen-sixteenths  { width: 93.75%; }

    /**
    * Hard-coded widths
    */
    .#{$namespace}width--giga        { width: $default-spacing-unit * 50   +px; } // 800px
    .#{$namespace}width--mega        { width: $default-spacing-unit * 30   +px; } // 480px
    .#{$namespace}width--kilo-mega   { width: $default-spacing-unit * 27   +px; } // 432px
    .#{$namespace}width--kilo        { width: $default-spacing-unit * 24   +px; } // 384px
    .#{$namespace}width--alpha       { width: $default-spacing-unit * 18   +px; } // 288px
    .#{$namespace}width--alfa-shift  { width: $default-spacing-unit * 16.125 +px; } // 258px
    .#{$namespace}width--beta        { width: $default-spacing-unit * 12   +px; } // 192px
    .#{$namespace}width--gamma       { width: $default-spacing-unit *  6   +px; } // 96px
    .#{$namespace}width--delta-gamma { width: $default-spacing-unit *  4.5 +px; } // 72 px
    .#{$namespace}width--delta       { width: $default-spacing-unit *  3   +px; } // 48px
    .#{$namespace}width--epsilon     { width: $default-spacing-unit *  2   +px; } // 32px
    .#{$namespace}width--zeta        { width: $default-spacing-unit *  1   +px; } // 16px
    .#{$namespace}width--milli       { width: $default-spacing-unit /  1.6 +px; } // 10px
    .#{$namespace}width--micro       { width: $default-spacing-unit /  2   +px; } // 8px
}

@include grid-setup();

@if $responsive == true {
    /**
     * With these classes you can define at which breakpoint you’d like an
     * element to be a certain size, e.g.:
     *
     * `<div class="g  one-quarter  lap-one-half  palm-one-whole"> ... </div>`
     *
     * This would create a `div` that, at ‘desktop’ sizes, takes up a quarter of the
     * horizontal space, a half of that space at ‘tablet’ sizes, and goes full width
     * at ‘mobile’ sizes.
     *
     * Demo: jsfiddle.net/inuitcss/WS4Ge
     *
     */

    @include media-query(palm) {
        @include grid-setup("palm-");
    }

    @include media-query(lap) {
        @include grid-setup("lap-");
    }

    @include media-query(lap-and-up) {
        @include grid-setup("lap-and-up-");
    }

    @include media-query(portable) {
        @include grid-setup("portable-");
    }

    @include media-query(desk) {
        @include grid-setup("desk-");
    }
}
