.pagination {
  font-size: 14px;
  color: $dark-grey;
  line-height: 16px;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }

  select::-ms-expand {
    display: none;
  }

  select:hover {
    border: none;
    box-shadow: none;
  }

  select:focus {
    outline-offset: unset;
    outline: none;
  }

  select:active,
  select:active:hover {
    box-shadow: none;
  }
}
