/*------------------------------------*\
     $STATS
 \*------------------------------------*/
 /**
  * Simple object to display key–value statistic-like information, e.g.:
  *
    <div class=stat-group>
        <dl class=stat>
            <dt class=stat__title>Tweets</dt>
            <dd class=stat__value>27,740</dd>
        </dl>

        <dl class=stat>
            <dt class=stat__title>Following</dt>
            <dd class=stat__value>11,529</dd>
        </dl>

        <dl class=stat>
            <dt class=stat__title>Followers</dt>
            <dd class=stat__value>12,105</dd>
        </dl>
    </div>
  *
  * Demo: jsfiddle.net/inuitcss/Bpwu6
  *
  */
.stat-group {
    @extend .cf;
    margin-left: -$default-spacing-unit +px;
    text-align: center;
}

.stat {
    flex-direction: column;
    float: left;
    font-weight: bold;
    padding-left: $default-spacing-unit +px;
    text-align: center;
    margin-left: $default-spacing-unit +px;
    display: flex;
    border-left: 1px solid $shade-3-color;

    &:first-child {
        border-left: none;
        padding-left: 0;
    }
}

.stat__title {
    order: 2;
    -ms-flex-order: 2;
    text-transform: uppercase;
    font-weight: normal;
}

.stat__value {
    @extend .gamma;
    order: 1;
    -ms-flex-order:1;
    margin-left: 0;
}
