.app-level-error {
  height: 100vh;
  .content {
    width: 50%;
    max-width: 500px;
    .tiny-title {
      font-size: 20px;
      font-family: $univers-light;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 6px;
    }
    .title {
      font-size: 34px;
      font-family: $univers-bold;
      line-height: 32px;
      font-weight: 400;
      color: $grey;
    }
    .message {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
