.page-width {
  width: 660px;
  margin-left: auto;
  margin-right: auto;
}
.new-bid-sections {
  .mode-selected {
    button {
      width: 204px;
      height: 80px;
      outline: none;
    }

    span {
      color: $dark-grey;
      font-family: $font-stack;
    }

    .selected {
      border: 2px solid $rfq-green;
    }

    .validation-message {
      color: $alert-red;
      font-size: 10px;
    }
  }
  .fsc-peg-helper {
    position: absolute;
    top: 10px;
    right: 8px;
    display: block;
    color: $blue;
    outline: none;
  }
  .section-grid {
    .grid__cell {
      padding-left: 4px;
      padding: 4px;
    }
  }
}
