/*------------------------------------*\
    $RULES
\*------------------------------------*/
/**
 * Horizontal rules, extend `hr`.
 * 
 * Demo: jsfiddle.net/inuitcss/L6GuZ
 * 
 */
hr {
    border: 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: $default-ui-color; /* Sets border color, extend in your theme stylesheet. */
    /**
     * `hr` elements only take up a few pixels, so we need to give them special
     * treatment regarding vertical rhythm.
     */
    margin-bottom: ($default-spacing-unit - 1) +px;
}

/**
 * Dotted rules
 */
.rule--dotted {
    border-bottom-style: dotted;
}

/**
 * Dashed rules
 */
.rule--dashed {
    border-bottom-style: dashed;
}

/**
 * Double-width rules
 */
.rule--double {
    border-bottom-width: 2px;
    margin-bottom: ($default-spacing-unit - 2) +px;
}
