/*------------------------------------*\
    $HELPER
\*------------------------------------*/
/**
 * A series of helper classes to use arbitrarily. Only use a helper class if an
 * element/component doesn’t already have a class to which you could apply this
 * styling, e.g. if you need to float `.main-nav` left then add `float:left;` to
 * that ruleset as opposed to adding the `.fl` class to the markup.
 */

/**
 * Full classes for sizing.
 */
.full-width {
    overflow: auto;
    width: 100%;
}

.full-height {
    height: 100%;
    overflow: auto;
}

@mixin bleed-setup($namespace: "") {
    /**
     * Pull items full width of `.island` parents.
     */
    .#{$namespace}full-bleed {
        margin: -$default-spacing-unit +px !important;

        .islet & {
            margin: -($half-spacing-unit) +px !important;
        }
    }

    .#{$namespace}full-bleed--horizontal {
        margin-left: -$default-spacing-unit +px !important;
        margin-right: -$default-spacing-unit +px !important;

        .islet & {
            margin-left: -($half-spacing-unit) +px !important;
            margin-right: -($half-spacing-unit) +px !important;
        }
    }

    .#{$namespace}full-bleed--vertical {
        margin-bottom: -$default-spacing-unit +px !important;
        margin-top: -$default-spacing-unit +px !important;

        .islet & {
            margin-bottom: -($half-spacing-unit) +px !important;
            margin-top: -($half-spacing-unit) +px !important;
        }
    }

    .#{$namespace}half-bleed {
        margin: -$half-spacing-unit +px !important;
    }

    .#{$namespace}half-bleed--horizontal {
        margin-left: -$half-spacing-unit +px !important;
        margin-right: -$half-spacing-unit +px !important;
    }

    .#{$namespace}half-bleed--vertical {
        margin-bottom: -$half-spacing-unit +px !important;
        margin-top: -$half-spacing-unit +px !important;
    }

    .#{$namespace}quarter-bleed {
        margin: -$quarter-spacing-unit +px !important;
    }

    .#{$namespace}quarter-bleed--horizontal {
        margin-left: -$quarter-spacing-unit +px !important;
        margin-right: -$quarter-spacing-unit +px !important;
    }

    .#{$namespace}quarter-bleed--vertical {
        margin-bottom: -$quarter-spacing-unit +px !important;
        margin-top: -$quarter-spacing-unit +px !important;
    }
}

@include bleed-setup();

@if $responsive == true {
    @include media-query(palm)          { @include bleed-setup("palm-"); }
    @include media-query(lap)           { @include bleed-setup("lap-"); }
    @include media-query(lap-and-up)    { @include bleed-setup("lap-and-up-"); }
    @include media-query(portable)      { @include bleed-setup("portable-"); }
    @include media-query(desk)          { @include bleed-setup("desk-"); }
}

/**
 * Mute an object by reducing its opacity.
 */
.muted {
    filter: alpha(opacity = 50)!important;
    opacity: .5!important;
}

/**
 * Make an object completely see-through. Used to
 * make select elements clickable but see through.
 */
.transparent {
    filter: alpha(opacity = 0)!important;
    opacity: 0!important;
}

.opaque {
    filter: alpha(opacity = 100)!important;
    opacity: 1!important;
}

/**
 * Add a right-angled quote to links that imply movement, e.g.:
 *
   <a href=# class=go>Read more</a>
 *
 */
.go:after {
    content: " »";
}

/**
 * Hide content without resorting to `display:none;`
 */
.accessibility {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.accessibility.focusable:active,
.accessibility.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/**
 * Apply white-space: nowrap.
 */
.nowrap {
    white-space: nowrap;
}

/**
 * Apply white-space: pre-wrap.
 */
.prewrap {
    white-space: pre-wrap;
}

/**
 * Object that takes up the entire width and height
 * of it's parent like a modal.
 */
.overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.highlighted {
    background-color: $yellow;
}

.highlight {
	background-color: #41ff23;
}

input:disabled + label span.highlight {
	background-color: #cdffc5 !important;
}

/**
 * Flip something upside down.
*/
.upside-down {
	transform: rotate(-180deg);
}

/**
 * Flip something left or right.
*/
.rotate-left {
    transform: rotate(90deg);
}

.rotate-right {
    transform: rotate(180deg);
}

.rotate-mirror {
    transform: rotateX(180deg);
}


/**
 * Hide in browser, show on print.
*/
.print-only {
    display: none !important;
}

/**
 * Bug in IE8 where a hidden object that contains content :before
 * display this content when the hidden class is removed. In JS
 * you can add and remove this class with a 1 milisecond delay to
 * have your :before content appear.
 */
.no-css-content:before,
.no-css-content:after {
    content: none!important;
}

/**
 * Position related classes
 */
@mixin pos-setup($namespace: "") {
    .#{$namespace}pos-absolute      { position: absolute; z-index: 1; }
    .#{$namespace}pos-relative      { position: relative; }
    .#{$namespace}pos-static        { position: static; }
    .#{$namespace}pos-fixed         { position: fixed; }
    .#{$namespace}pos-top           { top: 0; }
    .#{$namespace}pos-right         { right: 0; }
    .#{$namespace}pos-bottom        { bottom: 0; }
    .#{$namespace}pos-left          { left: 0; }
    .#{$namespace}pos-top--auto     { top: auto; }
    .#{$namespace}pos-right--auto   { right: auto; }
    .#{$namespace}pos-bottom--auto  { bottom: auto; }
    .#{$namespace}pos-left--auto    { left: auto; }
    .#{$namespace}pos-unset         { position : unset !important; }
    .#{$namespace}pos-absolute--wrapper-width { width: calc(100% - #{$default-spacing-unit * 2 +px}); }
}

@include pos-setup();

@if $responsive == true {
    @include media-query(palm)          { @include pos-setup("palm-"); }
    @include media-query(lap)           { @include pos-setup("lap-"); }
    @include media-query(lap-and-up)    { @include pos-setup("lap-and-up-"); }
    @include media-query(portable)      { @include pos-setup("portable-"); }
    @include media-query(desk)          { @include pos-setup("desk-"); }
}

/**
 * Change mouse cursor to indicate element is clickable. Change background
 * color of element when user hovers over element or selects element.
 */
.clickable {
    cursor: pointer;

    .stealthy {
        @extend .invisible;
    }

    @if $responsive == true {
        .palm-stealthy {
            @extend .palm-invisible;
        }

        .lap-stealthy {
            @extend .lap-invisible;
        }

        .lap-and-up-stealthy {
            @extend .lap-and-up-invisible;
        }

        .portable-stealthy {
            @extend .portable-invisible;
        }

        .desk-stealthy {
            @extend .desk-invisible;
        }
    }

    &.active,
    &:hover {
        background-color: #E6E7E8;

        .stealthy {
            visibility: visible !important;
        }

        @if $responsive == true {
            .palm-stealthy {
                visibility: visible !important;
            }

            .lap-stealthy {
                visibility: visible !important;
            }

            .lap-and-up-stealthy {
                visibility: visible !important;
            }

            .portable-stealthy {
                visibility: visible !important;
            }

            .desk-stealthy {
                visibility: visible !important;
            }
        }
    }
}

.round {
  border-radius: 50%;
}