/*------------------------------------*\
    $SPACE
\*------------------------------------*/
/**
 * Helper classes for spacing out elements or
 * tightening them up.
 */

/**
 * Add/remove margin
 */
@mixin space-setup($namespace: "") {
  .#{$namespace}p-0 {
    padding: 0;
  }
  .#{$namespace}p-1 {
    padding: 1px;
  }
  .#{$namespace}p-2 {
    padding: 2px;
  }
  .#{$namespace}p-4 {
    padding: 4px;
  }
  .#{$namespace}p-6 {
    padding: 6px;
  }
  .#{$namespace}p-8 {
    padding: 8px;
  }
  .#{$namespace}p-12 {
    padding: 12px;
  }
  .#{$namespace}p-16 {
    padding: 16px;
  }
  .#{$namespace}p-20 {
    padding: 16px;
  }
  .#{$namespace}p-24 {
    padding: 24px;
  }
  .#{$namespace}p-28 {
    padding: 28px;
  }
  .#{$namespace}p-32 {
    padding: 32px;
  }
  .#{$namespace}p-36 {
    padding: 36px;
  }
  .#{$namespace}p-40 {
    padding: 40px;
  }
  .#{$namespace}p-44 {
    padding: 44px;
  }
  .#{$namespace}p-48 {
    padding: 48px;
  }
  .#{$namespace}py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .#{$namespace}py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .#{$namespace}py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .#{$namespace}py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .#{$namespace}py-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .#{$namespace}py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .#{$namespace}py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .#{$namespace}py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .#{$namespace}py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .#{$namespace}py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .#{$namespace}py-28 {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .#{$namespace}py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .#{$namespace}py-36 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .#{$namespace}py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .#{$namespace}py-44 {
    padding-top: 44px;
    padding-bottom: 44px;
  }
  .#{$namespace}py-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .#{$namespace}px-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .#{$namespace}px-1 {
    padding-right: 1px;
    padding-left: 1px;
  }
  .#{$namespace}px-2 {
    padding-right: 2px;
    padding-left: 2px;
  }
  .#{$namespace}px-4 {
    padding-right: 4px;
    padding-left: 4px;
  }
  .#{$namespace}px-6 {
    padding-right: 6px;
    padding-left: 6px;
  }
  .#{$namespace}px-8 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .#{$namespace}px-12 {
    padding-right: 12px;
    padding-left: 12px;
  }
  .#{$namespace}px-16 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .#{$namespace}px-20 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .#{$namespace}px-24 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .#{$namespace}px-28 {
    padding-right: 28px;
    padding-left: 28px;
  }
  .#{$namespace}px-32 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .#{$namespace}px-36 {
    padding-right: 36px;
    padding-left: 36px;
  }
  .#{$namespace}px-40 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .#{$namespace}px-44 {
    padding-right: 44px;
    padding-left: 44px;
  }
  .#{$namespace}px-48 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .#{$namespace}pt-0 {
    padding-top: 0;
  }
  .#{$namespace}pt-1 {
    padding-top: 1px;
  }
  .#{$namespace}pt-2 {
    padding-top: 2px;
  }
  .#{$namespace}pt-4 {
    padding-top: 4px;
  }
  .#{$namespace}pt-6 {
    padding-top: 6px;
  }
  .#{$namespace}pt-8 {
    padding-top: 8px;
  }
  .#{$namespace}pt-12 {
    padding-top: 12px;
  }
  .#{$namespace}pt-16 {
    padding-top: 16px;
  }
  .#{$namespace}pt-20 {
    padding-top: 20px;
  }
  .#{$namespace}pt-24 {
    padding-top: 24px;
  }
  .#{$namespace}pt-28 {
    padding-top: 28px;
  }
  .#{$namespace}pt-32 {
    padding-top: 32px;
  }
  .#{$namespace}pt-36 {
    padding-top: 36px;
  }
  .#{$namespace}pt-40 {
    padding-top: 40px;
  }
  .#{$namespace}pt-44 {
    padding-top: 44px;
  }
  .#{$namespace}pt-48 {
    padding-top: 48px;
  }
  .#{$namespace}pr-0 {
    padding-right: 0;
  }
  .#{$namespace}pr-1 {
    padding-right: 1px;
  }
  .#{$namespace}pr-2 {
    padding-right: 2px;
  }
  .#{$namespace}pr-4 {
    padding-right: 4px;
  }
  .#{$namespace}pr-6 {
    padding-right: 6px;
  }
  .#{$namespace}pr-8 {
    padding-right: 8px;
  }
  .#{$namespace}pr-12 {
    padding-right: 12px;
  }
  .#{$namespace}pr-16 {
    padding-right: 16px;
  }
  .#{$namespace}pr-20 {
    padding-right: 20px;
  }
  .#{$namespace}pr-24 {
    padding-right: 24px;
  }
  .#{$namespace}pr-28 {
    padding-right: 28px;
  }
  .#{$namespace}pr-32 {
    padding-right: 32px;
  }
  .#{$namespace}pr-36 {
    padding-right: 36px;
  }
  .#{$namespace}pr-40 {
    padding-right: 40px;
  }
  .#{$namespace}pr-44 {
    padding-right: 44px;
  }
  .#{$namespace}pr-48 {
    padding-right: 48px;
  }
  .#{$namespace}pb-0 {
    padding-bottom: 0;
  }
  .#{$namespace}pb-1 {
    padding-bottom: 1px;
  }
  .#{$namespace}pb-2 {
    padding-bottom: 2px;
  }
  .#{$namespace}pb-4 {
    padding-bottom: 4px;
  }
  .#{$namespace}pb-6 {
    padding-bottom: 6px;
  }
  .#{$namespace}pb-8 {
    padding-bottom: 8px;
  }
  .#{$namespace}pb-12 {
    padding-bottom: 8px;
  }
  .#{$namespace}pb-16 {
    padding-bottom: 16px;
  }
  .#{$namespace}pb-20 {
    padding-bottom: 20px;
  }
  .#{$namespace}pb-24 {
    padding-bottom: 24px;
  }
  .#{$namespace}pb-28 {
    padding-bottom: 28px;
  }
  .#{$namespace}pb-32 {
    padding-bottom: 32px;
  }
  .#{$namespace}pb-36 {
    padding-bottom: 36px;
  }
  .#{$namespace}pb-40 {
    padding-bottom: 40px;
  }
  .#{$namespace}pb-44 {
    padding-bottom: 44px;
  }
  .#{$namespace}pb-48 {
    padding-bottom: 48px;
  }
  .#{$namespace}pl-0 {
    padding-left: 0;
  }
  .#{$namespace}pl-1 {
    padding-left: 1px;
  }
  .#{$namespace}pl-2 {
    padding-left: 2px;
  }
  .#{$namespace}pl-4 {
    padding-left: 4px;
  }
  .#{$namespace}pl-6 {
    padding-left: 6px;
  }
  .#{$namespace}pl-8 {
    padding-left: 8px;
  }
  .#{$namespace}pl-12 {
    padding-left: 12px;
  }
  .#{$namespace}pl-16 {
    padding-left: 16px;
  }
  .#{$namespace}pl-20 {
    padding-left: 20px;
  }
  .#{$namespace}pl-24 {
    padding-left: 24px;
  }
  .#{$namespace}pl-28 {
    padding-left: 28px;
  }
  .#{$namespace}pl-32 {
    padding-left: 32px;
  }
  .#{$namespace}pl-36 {
    padding-left: 36px;
  }
  .#{$namespace}pl-40 {
    padding-left: 40px;
  }
  .#{$namespace}pl-44 {
    padding-left: 44px;
  }
  .#{$namespace}pl-48 {
    padding-left: 48px;
  }

  .#{$namespace}m-0 {
    margin: 0;
  }
  .#{$namespace}m-1 {
    margin: 1px;
  }
  .#{$namespace}m-2 {
    margin: 2px;
  }
  .#{$namespace}m-4 {
    margin: 4px;
  }
  .#{$namespace}m-6 {
    margin: 6px;
  }
  .#{$namespace}m-8 {
    margin: 8px;
  }
  .#{$namespace}m-12 {
    margin: 8px;
  }
  .#{$namespace}m-16 {
    margin: 16px;
  }
  .#{$namespace}m-20 {
    margin: 16px;
  }
  .#{$namespace}m-24 {
    margin: 24px;
  }
  .#{$namespace}m-28 {
    margin: 24px;
  }
  .#{$namespace}m-32 {
    margin: 32px;
  }
  .#{$namespace}m-36 {
    margin: 32px;
  }
  .#{$namespace}m-40 {
    margin: 40px;
  }
  .#{$namespace}m-44 {
    margin: 40px;
  }
  .#{$namespace}m-48 {
    margin: 48px;
  }
  .#{$namespace}m-auto {
    margin: auto;
  }
  .#{$namespace}my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .#{$namespace}my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .#{$namespace}my-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .#{$namespace}my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .#{$namespace}my-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .#{$namespace}my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .#{$namespace}my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .#{$namespace}my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .#{$namespace}my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .#{$namespace}my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .#{$namespace}my-28 {
    margin-top: 28px;
    margin-bottom: 28px;
  }
  .#{$namespace}my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .#{$namespace}my-36 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .#{$namespace}my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .#{$namespace}my-44 {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  .#{$namespace}my-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .#{$namespace}my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .#{$namespace}mx-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .#{$namespace}mx-1 {
    margin-right: 1px;
    margin-left: 1px;
  }
  .#{$namespace}mx-2 {
    margin-right: 2px;
    margin-left: 2px;
  }
  .#{$namespace}mx-4 {
    margin-right: 4px;
    margin-left: 4px;
  }
  .#{$namespace}mx-6 {
    margin-right: 6px;
    margin-left: 6px;
  }
  .#{$namespace}mx-8 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .#{$namespace}mx-12 {
    margin-right: 12px;
    margin-left: 12px;
  }
  .#{$namespace}mx-16 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .#{$namespace}mx-20 {
    margin-right: 20px;
    margin-left: 20px;
  }
  .#{$namespace}mx-24 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .#{$namespace}mx-32 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .#{$namespace}mx-36 {
    margin-right: 36px;
    margin-left: 36px;
  }
  .#{$namespace}mx-40 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .#{$namespace}mx-44 {
    margin-right: 44px;
    margin-left: 44px;
  }
  .#{$namespace}mx-48 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .#{$namespace}mx-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .#{$namespace}mt-0 {
    margin-top: 0;
  }
  .#{$namespace}mt-1 {
    margin-top: 1px;
  }
  .#{$namespace}mt-2 {
    margin-top: 2px;
  }
  .#{$namespace}mt-4 {
    margin-top: 4px;
  }
  .#{$namespace}mt-6 {
    margin-top: 6px;
  }
  .#{$namespace}mt-8 {
    margin-top: 8px;
  }
  .#{$namespace}mt-12 {
    margin-top: 12px;
  }
  .#{$namespace}mt-16 {
    margin-top: 16px;
  }
  .#{$namespace}mt-20 {
    margin-top: 20px;
  }
  .#{$namespace}mt-24 {
    margin-top: 24px;
  }
  .#{$namespace}mt-28 {
    margin-top: 28px;
  }
  .#{$namespace}mt-32 {
    margin-top: 32px;
  }
  .#{$namespace}mt-36 {
    margin-top: 36px;
  }
  .#{$namespace}mt-40 {
    margin-top: 40px;
  }
  .#{$namespace}mt-44 {
    margin-top: 44px;
  }
  .#{$namespace}mt-48 {
    margin-top: 48px;
  }
  .#{$namespace}mt-auto {
    margin-top: auto;
  }
  .#{$namespace}mr-0 {
    margin-right: 0;
  }
  .#{$namespace}mr-1 {
    margin-right: 1px;
  }
  .#{$namespace}mr-2 {
    margin-right: 2px;
  }
  .#{$namespace}mr-4 {
    margin-right: 4px;
  }
  .#{$namespace}mr-6 {
    margin-right: 6px;
  }
  .#{$namespace}mr-8 {
    margin-right: 8px;
  }
  .#{$namespace}mr-12 {
    margin-right: 12px;
  }
  .#{$namespace}mr-16 {
    margin-right: 16px;
  }
  .#{$namespace}mr-20 {
    margin-right: 20px;
  }
  .#{$namespace}mr-24 {
    margin-right: 24px;
  }
  .#{$namespace}mr-28 {
    margin-right: 28px;
  }
  .#{$namespace}mr-32 {
    margin-right: 32px;
  }
  .#{$namespace}mr-36 {
    margin-right: 36px;
  }
  .#{$namespace}mr-40 {
    margin-right: 40px;
  }
  .#{$namespace}mr-44 {
    margin-right: 44px;
  }
  .#{$namespace}mr-48 {
    margin-right: 48px;
  }
  .#{$namespace}mr-auto {
    margin-right: auto;
  }
  .#{$namespace}mb-0 {
    margin-bottom: 0;
  }
  .#{$namespace}mb-1 {
    margin-bottom: 1px;
  }
  .#{$namespace}mb-2 {
    margin-bottom: 2px;
  }
  .#{$namespace}mb-4 {
    margin-bottom: 4px;
  }
  .#{$namespace}mb-6 {
    margin-bottom: 6px;
  }
  .#{$namespace}mb-8 {
    margin-bottom: 8px;
  }
  .#{$namespace}mb-12 {
    margin-bottom: 12px;
  }
  .#{$namespace}mb-16 {
    margin-bottom: 16px;
  }
  .#{$namespace}mb-20 {
    margin-bottom: 20px;
  }
  .#{$namespace}mb-24 {
    margin-bottom: 24px;
  }
  .#{$namespace}mb-28 {
    margin-bottom: 28px;
  }
  .#{$namespace}mb-32 {
    margin-bottom: 32px;
  }
  .#{$namespace}mb-36 {
    margin-bottom: 36px;
  }
  .#{$namespace}mb-40 {
    margin-bottom: 40px;
  }
  .#{$namespace}mb-44 {
    margin-bottom: 44px;
  }
  .#{$namespace}mb-48 {
    margin-bottom: 48px;
  }
  .#{$namespace}mb-auto {
    margin-bottom: auto;
  }
  .#{$namespace}ml-0 {
    margin-left: 0;
  }
  .#{$namespace}ml-1 {
    margin-left: 1px;
  }
  .#{$namespace}ml-2 {
    margin-left: 2px;
  }
  .#{$namespace}ml-4 {
    margin-left: 4px;
  }
  .#{$namespace}ml-6 {
    margin-left: 6px;
  }
  .#{$namespace}ml-8 {
    margin-left: 8px;
  }
  .#{$namespace}ml-12 {
    margin-left: 12px;
  }
  .#{$namespace}ml-16 {
    margin-left: 16px;
  }
  .#{$namespace}ml-20 {
    margin-left: 20px;
  }
  .#{$namespace}ml-24 {
    margin-left: 24px;
  }
  .#{$namespace}ml-28 {
    margin-left: 28px;
  }
  .#{$namespace}ml-32 {
    margin-left: 32px;
  }
  .#{$namespace}ml-36 {
    margin-left: 36px;
  }
  .#{$namespace}ml-40 {
    margin-left: 40px;
  }
  .#{$namespace}ml-44 {
    margin-left: 44px;
  }
  .#{$namespace}ml-48 {
    margin-left: 48px;
  }
  .#{$namespace}ml-auto {
    margin-left: auto;
  }
}

@include space-setup();

@if $responsive == true {
  @include media-query(palm) {
    @include space-setup("palm-");
  }

  @include media-query(lap) {
    @include space-setup("lap-");
  }

  @include media-query(lap-and-up) {
    @include space-setup("lap-and-up-");
  }

  @include media-query(portable) {
    @include space-setup("portable-");
  }

  @include media-query(desk) {
    @include space-setup("desk-");
  }
}
