/*------------------------------------*\
    $MAIN
\*------------------------------------*/

html,
body,
button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

html {
  color: $default-text-color;
  font: ($default-font-size / 16 + em)/ ($line-height-ratio) $font-stack;
}

html,
body {
  height: 100%;
  background-color: $site-background-color;
}

#root {
  background-color: $site-background-color;
  min-height: 100%;
  min-width: $lap-end;
}

.no-focus {
  outline: none !important;
}

.tooltiptext {
  display: none;
}

.tooltip-tp:hover .tooltiptext {
  display: block !important;
  position: absolute;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  background: #ffffff;
  width: 240px;
  float: right;
  margin-left: 36px;
  top: 0;
  padding: 20px;
  text-align: left;
}

#app-title {
  font-family: $univers-bold;
}

.responsive--dimmer {
  background: rgba(#000, 0.5);
  bottom: 0;
  display: block;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 110;
}

.search-box:focus {
  outline-color: $rfq-green;
}

@keyframes gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

.content-placeholder {
  span {
    display: block;
    height: 12px;
    background: linear-gradient(
      to right,
      $site-background-color 20%,
      #ddd 50%,
      $site-background-color 80%
    );
    background-size: 500px 100px;
    animation: gradient 15s ease infinite;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    border-radius: 2px;
  }
}

.border-color-gray {
  border-color: $med-grey-1 !important;
}

.file-drop-progress {
  border: 1px dashed $rfq-green !important;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-24 {
  font-size: 24px;
}

.font-bold {
  font-weight: bold;
}

.page-container {
  width: 991px;
  margin: 0px auto;
}

@media screen and (max-width: 1024px) {
  .page-container {
    width: 94%;
  }
}

.border-top-grey {
  border-top: 1px solid $shade-3-color;
}

.border-bottom-grey {
  border-bottom: 1px solid $shade-3-color;
}

.border-left-grey {
  border-left: 1px solid $shade-3-color;
}

.border-right-grey {
  border-right: 1px solid $shade-3-color;
}

.border-right-silver {
  border-right: 2px solid $silver;
}

.cursor-pointer {
  cursor: pointer;
}

.color-grey {
  color: #666666;
}

.hover-part {
  display: none;
}

.grid-hover:hover {
  background: $site-background-color;
  cursor: pointer;

  .hover-part {
    display: inline-block !important;
  }
}

.widget {
  font-family: $univers-cond;
  font-size: 16px !important;
  padding-left: 24px !important;
  line-height: 32px;
}

.univers-67-bold-cond {
  font-family: $univers-boldcond !important;
}

.univers-65-bold {
  font-family: $univers-bold !important;
}

.univers-45-light {
  font-family: $univers-light;
}

.font-size-14 {
  font-size: 14px !important;
}

.word-wrap {
  text-align: left;
  max-width: 96%;
  white-space: pre-wrap;
  word-break: break-word;
}

.scroll::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.scroll::-webkit-scrollbar-thumb {
  background: $shade-3-color;
  border-radius: 3.5px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 0px;
}

.resize-none {
  resize: none;
}

.scroll-on-hover {
  overflow: hidden;

  &:hover {
    overflow: auto;
  }
}

.app-state {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-holder {
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.decoration-none {
  text-decoration: none !important;
}

.display-table {
  display: table !important;
}

::-webkit-resizer {
  background-color: transparent;
}

.inline-grid {
  display: inline-grid;
}

.light-blue {
  color: #1893ff;
}

.light-grey {
  color: #8893a3;
}

.text-red-failure-dark {
  color: $red-failure-dark !important;
}

.text-grayish-blue {
  color: $grayish-blue-2 !important;
}

.bg-cobalt-blue {
  background-color: $cobalt-blue !important;
}

.border-cobalt-blue {
  border: 1px solid $cobalt-blue;
}

input[type="checkbox"]:indeterminate::before {
  content: "";
  display: block;
  color: $white;
  width: 12px;
  height: 12.5px;
  background-color: $blue;
  border-radius: 20%;
}

input[type="checkbox"]:indeterminate::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border: solid $white;
  border-width: 2px 0 0 0;
  position: relative;
  top: -7px;
  left: 1px;
}

.text-transform-none {
  text-transform: none !important;
}

.text-cobalt-blue {
  color: $cobalt-blue;
}
