/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

/**
* Button styles for new UI/UX design system
* https://projects.invisionapp.com/d/main/#/console/17020525/352851055/preview
*/
%base-button {
    color: $black;
    font-family: $univers-cond;
    font-size: 16px;
    outline: none;
    text-transform: uppercase;
    text-decoration: none;
    height: 40px;
    min-width: 125px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    outline: none;

    &[disabled],
    &[disabled]:active,
    &.disabled,
    &.disabled:active {
        color: #343434;
    }
}

.text-button {
    @extend %base-button;

    background-color: transparent;
    color: $blue;
    font-size: 14px;

    &:hover,
    &:focus {
        background-color: #dbeaf1;
    }

    &:active,
    &.active {
        background-color: transparent;
    }

    &[disabled],
    &[disabled]:active,
    &.disabled,
    &.disabled:active {
        background-color: transparent;
    }
}

.primary-button {
    @extend %base-button;
    background-color: #69c764;

    &:hover,
    &:focus {
        background-color: #39d030;
    }

    &:active,
    &.active {
        background-color: #39d030;
    }

    &[disabled],
    &[disabled]:active,
    &.disabled,
    &.disabled:active {
        background-color: #9df58f;
        color: #9a9a9a;
        font-family: $univers-cond !important;
    }
}

.secondary-button {
    @extend %base-button;
    background-color: transparent;
    border: 2px solid #69c764;
    &:hover,
    &:focus {
        border: 2px solid #39d030;
    }
    &:active,
    &.active {
        background-color: transparent;
    }
    &[disabled],
    &[disabled]:active,
    &.disabled,
    &.disabled:active {
        background-color: transparent;
        border-color: #aff0b4;
        color: #9a9a9a;
    }
}
.tertiary-button {
    @extend %base-button;
    background-color: transparent;
    border: 2px solid $med-grey-2;
    &:hover,
    &:focus {
        background-color: $light-grey-2;
    }
    &:active,
    &.active {
        background-color: transparent;
    }
    &[disabled],
    &[disabled]:active,
    &.disabled,
    &.disabled:active {
        background-color: transparent;
        border-color: #e6e7e8;
    }
}

.short-button {
    min-width: 96px;
}

.spinner-button{
    background-color: #dbeaf1;
    height: 32px;
}