/*------------------------------------*\
    $VARS
\*------------------------------------*/
/**
 * Set up project-specific variables here which are then used throughout your
 * build. These variables are automatically picked up by inuit.css so you should
 * never need to modify any framework code.
 */

/**
 * Base stuff
 */
$default-font-size: 12 !default;
$default-line-height: 16 !default;
$mobile-font-size: 16 !default;

$line-height-ratio: 1.33334;

/**
 * How big would you like round corners to be by default?
 */
$brand-round: 3 !default;

/**
 * Assign our `$default-line-height` to a new spacing var for more transparency.
 */
$default-spacing-unit: $default-line-height !default;
$half-spacing-unit: $default-spacing-unit / 2 !default;
$quarter-spacing-unit: $default-spacing-unit / 4 !default;

/**
 * Font-sizes (in pixels). Refer to relevant sections for their implementations.
 */
$giga-size: 72 !default; //giga
$mega-size: 54 !default; //mega
$kilo-size: 36 !default; //kilo

$h1-size: 22 !default; //alpha
$h2-size: 22 !default; //beta
$h3-size: 18 !default; //gamma
$h4-size: 16 !default; //delta
$h5-size: 12 !default; //epsilon
$h6-size: 12 !default; //zeta

$milli-size: 11 !default; //milli
$micro-size: 10 !default; //micro

/**
 * Default font stack.
 */
$font-stack: "Helvetica Neue", Helvetica, Arial, sans-serif !default;

/**
 * Brand stuff
 */
$blue: #0088d1 !default;
$blue-light: tint($blue, 85%) !default;
$green: #27d434 !default;
$green-light: tint($green, 85%) !default;
$green-2-light: #def8e0 !default;
$orange: #ff9715 !default;
$orange-light: tint($orange, 85%) !default;
$red: #ff572b !default;
$red-light: tint($red, 85%) !default;
$yellow: #ffe200 !default;
$yellow-light: tint($yellow, 85%) !default;
$red-medium: rgba(255, 0, 0, 0.2) !default;
$orange-medium: rgba(255, 126, 0, 0.3) !default;
$yellow-medium: rgba(255, 239, 50, 0.4) !default;
$cobalt-blue: #0066fa !default;
$cobalt-blue-light: #aed4fd !default;
$cobalt-blue-60: #0053c8 !default;

$shade-1-color: #343434 !default;
$shade-2-color: #adafb2 !default;
$shade-3-color: #c9cacc !default;
$shade-4-color: #e6e7e8 !default;
$shade-5-color: #f9f9f9 !default;
$shade-6-color: #f0f0f0 !default;
$shade-7-color: #f5f5f6 !default;

$default-ui-color: $shade-3-color;
$default-text-color: $shade-1-color !default;
$link-color: #000;
$disabled-tab-color: #7c7c7c !default;

$brand-color: #41ff23;
$brand-color-dark: #3bea1f !default;
$brand-secondary-color: #14dcfa;

/**
 * Equipment type colors
 */

$equipment-container-color: #ff7fc7;
$equipment-curtain-color: #ff7fc7;
$equipment-euroliner-color: #00ffff;
$equipment-flatbed-color: #16e47e;
$equipment-low-loader-color: #ff9480;
$equipment-reefer-color: #ff9480;
$equipment-multiple-color: #838afa;
$equipment-other-color: #ffff00;
$equipment-power-only-color: #1ab7ee;
$equipment-van-color: #fd7979;

/**
 * Load requirements colors
 */

$req-main-color: #d4f9ff;
$req-equipment-color: #d3ffd7;
$req-stop-color: #e6e7e8;

/**
 * animation settings
 */
$default-animation-duration: 0.5s;
$default-animation-timing-function: ease;
$default-animation-fill-mode: both;
$default-animation-iterations: 1;
$default-animation-direction: normal;
$default-animation-play-state: running;
$default-animation-delay: 2s;

$responsive: true !default;

/**
 * Tell inuit.css when breakpoints start.
 */
$palm-end: 768px !default;
$lap-start: $palm-end + 1px !default;
$lap-end: 1024px;
$desk-start: $lap-end + 1px !default;

$drop-shadow: 0 $brand-round + px 0 0 rgba(0, 0, 0, 0.15);

/**
 * Variables for new UI/UX design system
 * https://projects.invisionapp.com/d/main/#/console/17020525/352851051/preview
 */

$univers-light: "UniversLTPro-45Light";
$univers-roman: "UniversLTPro-55Roman";
$univers-bold: "UniversLTPro-65Bold";
$univers-cond: "UniversLTPro-57Cond";
$univers-boldcond: "UniversLTPro-67BoldCond";

$alpha-body-copy-size: 18px;
$beta-body-copy-size: 14px;
$gamma-body-copy-size: 12px;
$delta-body-copy-size: 10px;

$alpha-body-copy-line-height: 24px;
$beta-body-copy-line-height: 16px;
$gamma-body-copy-line-height: 12px;
$delta-body-copy-line-height: 12px;

$black: #000 !default;
$dark-grey: #343434 !default;
$med-grey-1: #adafb2 !default;
$med-grey-2: #c9cacc !default;
$med-grey-3: #6a778b !default;
$light-grey-1: #e6e7e8 !default;
$light-grey-2: #f5f5f6 !default;
$light-grey-3: #e9e9e9 !default;
$light-grey-4: #e9eced !default;
$light-grey-5: #dfe1e6 !default;
$dim-grey: #616161 !default;
$grey: #666666 !default;
$white: #fff !default;
$silver: #c1c1c1 !default;
$light-silver: #d8d8d8 !default;
$picton-blue: #53a6d8 !default;
$coconut-white: #e4eaf4 !default;
$light-yellow: #ffe388 !default;
$dark-brown: #5f5445 !default;

$alert-red: #790000 !default;
$alert-yellow: #ffe200 !default;
$alert-green: #2c9332 !default;

$site-background-color: #eff2fa;
$rfq-green: #69c764;
$rfq-red: #b00020;

$card-list-back-color: #e2e8f3;
$grayish-blue: #485465;
$grayish-blue-2: #49586d;
$red-failure: #ffbdaf;
$red-failure-dark: #c71e0c;
