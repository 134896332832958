.tooltip {
  background-color: $dark-grey;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  color: white;
  font-size: 12px;
  line-height: 14px;
  padding: 5px 8px;

  & > .tippy-arrow::before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }

  &[data-placement^="right"] > .tippy-arrow {
    left: 0;
    transform: translate3d(2px, 3px, 0px) !important;

    &::before {
      left: -7px;
      border-width: 8px 8px 8px 0;
      border-right-color: $dark-grey;
      transform-origin: center right;
    }
  }

  &[data-placement^="left"] > .tippy-arrow {
    right: 0;
    transform: translate3d(-2px, 3px, 0px) !important;

    &::before {
      border-width: 8px 0 8px 8px;
      border-left-color: $dark-grey;
      right: -7px;
      transform-origin: center left;
    }
  }

  &[data-placement^="top"] > .tippy-arrow {
    bottom: 0;
    transform: rotate(45deg) !important;
    right: 34px;
    left: auto !important;
    top: auto !important;

    &::before {
      bottom: 8px;
      border-width: 8px 8px 0 8px;
      border-right-color: $dark-grey;
      transform-origin: center top;
    }
  }

  &[data-placement^="bottom"] > .tippy-arrow {
    top: 0;
    transform: rotate(-45deg) !important;
    right: 34px;
    left: auto !important;
    bottom: auto !important;

    &::before {
      top: 8px;
      border-width: 0px 8px 8px 8px;
      border-right-color: $dark-grey;
      transform-origin: center bottom;
    }
  }
}
