.procurement {
  width: 100%;
  .panel {
    background-color: $white;
    position: relative;
    display: flex;
    height: 376px;
    justify-content: center;
    align-items: center;
  }

  .no-lanes-title {
    font-size: 34px;
    font-family: $univers-bold;
    line-height: 32px;
    font-weight: 400;
    color: $grey;
  }

  .filters {
    min-width: 96px;
    text-transform: none;
    font-family: $univers-bold;
    font-size: 14px;
  }

  .table-width {
    width: calc(100vw - 77px);
  }

  .capacity-match-grid {
    align .row:last-child {
      border-radius: 0 0 4px 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      border-bottom: 0px;
    }

    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-radius: 0 0 4px 4px;
  }

  .header-row {
    height: 48px;
  }

  .header-div {
    border-bottom: 1px solid $light-grey-3;
  }

  .header-cell {
    border-bottom: 0px;
    user-select: none;
    position: sticky;
    top: 0;
    background: $white;
    padding-top: 7px;

    &:hover {
      background: $site-background-color;
    }

    .resizable {
      resize: horizontal;
      overflow: auto;
      display: block;
      min-width: fit-content;
      &:hover {
        background-image: url("../../images/ResizeIcon.svg");
        background-repeat: no-repeat;
        background-position: bottom right;
      }
    }
  }

  .header-cell:first-child {
    padding-left: 0px;
  }

  .header-cell:last-child {
    padding-right: 0px;
  }

  .header-cell + .right {
    text-align: right;
  }

  .body-cell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .row {
    height: 48px;
    &:hover {
      background-color: $coconut-white;
      cursor: pointer;
    }
  }

  .sorted-header {
    background: $site-background-color;
  }

  .carriers-cell {
    line-height: 16px;
    display: block;
    border-radius: 100px;
    text-align: center;
    font-weight: 700;
    background-color: $cobalt-blue-light;
    width: 75px;
  }

  .ups-tag {
    background-color: #0e1a28;
    color: #ffc400;
    width: 55px;
  }

  .zero-carriers-cell,
  .zero-match-cell {
    background-color: $light-grey-4 !important;
    color: $med-grey-3;
  }

  .no-carriers-cell {
    background-color: $red-failure !important;
    width: 50px;
  }

  .match-cell {
    line-height: 16px;
    display: inline-block;
    border-radius: 100px;
    text-align: center;
    font-weight: 700;
    background-color: $light-grey-5;
    width: 25px;
  }

  .view-button {
    color: $cobalt-blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .view-button:disabled {
    color: $picton-blue;
  }

  .blue-button {
    &[disabled],
    &[disabled]:active,
    &.disabled,
    &.disabled:active {
      color: $med-grey-3 !important;
      background-color: $light-grey-4 !important;
    }
  }

  .drawer {
    .header {
      color: $shade-1-color;
    }
  }

  .action-steps {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $light-grey-5;
  }

  .lane-info {
    th {
      border: 0;
      font-size: 14px;
    }

    td {
      border: 0;
    }
  }

  .carrier-info {
    th,
    td {
      border: 0;
    }
  }

  .cap-source {
    width: 202%;
    height: 50px;
  }

  .submission-details {
    textarea {
      border-radius: 4px !important;
      padding-right: 28px;
    }

    .auto-complete__control,
    .outlined-input .control {
      border-radius: 0px;
    }

    .grid__cell:first-child .auto-complete__control {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .grid__cell:last-child .control {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .submission-pricing {
    .grid__cell {
      padding: 10px 8px 10px 8px;
    }

    .body {
      border-top: 1px solid $shade-2-color;
      cursor: pointer;

      .grid__cell:not(:first-child) {
        padding-top: 12px;
        padding-bottom: 12px;
      }

      .carriers-cell {
        display: inline-block;
        padding: 0px !important;
        margin: 24px 8px 10px 8px;
      }

      .accordion {
        background-color: $site-background-color;
        border-bottom: 1px solid $shade-2-color;
      }

      .outlined-input {
        height: 36px;
      }
    }
  }

  .menu {
    box-shadow: none;
  }

  #menu-dropdown li {
    &:hover {
      background-color: $coconut-white;
    }
  }

  .filter-region {
    visibility: hidden;
  }

  .header-cell:hover .filter-region {
    visibility: visible;
  }

  .filter-region[aria-expanded="true"] {
    visibility: visible;
  }

  .drop-indicator {
    border-left: 1px $shade-1-color solid;
  }
}
