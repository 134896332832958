/*------------------------------------*\
    ADD-IN
\*------------------------------------*/
/**
 * Used to place icons into input fields such-as a search icon
 * into a to search input fields.
 *
    <label class="add-in one-whole">
        <svg class="add-in__left-item svg-icon--search" aria-label="Search">
            <use xlink:href="#svg-icon--search"></use>
        </svg>
        <input id="search" name="search" class="text-input one-whole" type="text">
    </label>

    <label class="add-in">
        <svg class="add-in__right-item svg-icon--clear" aria-label="Clear">
            <use xlink:href="#svg-icon--clear"></use>
        </svg>
        <svg class="add-in__right-item svg-icon--icon--arrow-5-down" aria-label="More Options">
            <use xlink:href="#svg-icon--icon--arrow-5-down"></use>
        </svg>
        <input id="input-field" name="input-field" class="text-input one-whole" type="text">
    </label>

 * Values below are hard-coded in pixels due to
 * differences in the calculation of ems across
 * Windows browsers and OSX browsers *
 */

.add-in {
    display: inline-block;
    position: relative;

    .add-in__left-item,
    .add-in__right-item {
        transform: translateY(-50%);
        position: absolute;
        text-align: center;
        top: 50%;
        margin-right: 12px;
    }

    .add-in__left-item:not(.hidden) {
        left: 0;

        & ~ .text-input {
            padding-left: $half-spacing-unit * 3 +px;
        }
    }

    .add-in__right-item {
        right: 0;

        & ~ .text-input {
            padding-right: $half-spacing-unit * 3 +px;
        }

        & ~ .add-in__right-item {
            right: $half-spacing-unit * 3 +px;

            & ~ .text-input {
                padding-right: $default-spacing-unit * 3 +px;
            }

            &.hidden {
                right: 0;

                & ~ .text-input {
                    padding-right: $half-spacing-unit * 3 +px;
                }
            }
        }

        &.hidden ~ .text-input {
            padding-right: 5px; // Standard .text-input padding value
        }

        &.hidden ~ .add-in__right-item {
            right: 0;

            & ~ .text-input {
                padding-right: $half-spacing-unit * 3 +px;
            }

            &.hidden {
                right: 0;

                & ~ .text-input {
                    padding-right: 5px; // Standard .text-input padding value
                }
            }
        }
    }
}

/*------------------------------------*\
    ADD-ON
\*------------------------------------*/
/**
 * Just like an add-in only it gets set next
 * to another element verse inside of it.
 *
    <label for="search-input" class="add-on__start-item">Label</label>
    <div class="add-on">
        <div class="add-on__section one-whole">
            <input type="text" id="search-input" class="add-on__start-item text-input">
        </div>
        <div class="add-on__section">
            <button type="submit" class="add-on__end-item button button--loud">
                <span class="accessiblity">Search</span>
                <svg class="svg-icon--search" aria-hidden="true">
                    <use xlink:href="#svg-icon--search"></use>
                </svg>
            </button>
        </div>
    </div>

    Combining add-in with add-on

    <label for="search-input">Search</label>
    <div class="add-on">
        <div class="add-on__section one-whole">
            <div class="add-in one-whole">
                <button class="add-in__right-item text-link" type="reset">
                    <span class="accessibility">Clear Input</span>
                    <svg class="svg-icon--clear" aria-label="Clear">
                        <use xlink:href="#svg-icon--clear"></use>
                    </svg>
                </button>
                <input type="text" id="search-input" class="add-on__start-item text-input one-whole">
            </div>
        </div>
        <div class="add-on__section">
            <button type="submit" id="advanced-lane-search-submit" class="add-on__end-item button button--loud">
                <span class="accessibility">Search</span>
                <svg class="svg-icon--search" aria-hidden="true">
                    <use xlink:href="#svg-icon--search"></use>
                </svg>
            </button>
        </div>
    </div>
 */
.add-on {
    display: table;
    white-space: nowrap;

    > .add-on__section {

        &:first-child .add-on__item {
            @include border-right-radius(0);
        }

        &:last-child .add-on__item {
            @include border-left-radius(0);
            border-left-width: 0;
        }

        &:not(:first-child):not(:last-child) .add-on__item {
            border-radius: 0;
            border-left-width: 0;
        }

        // To account for when add-on items also contain
        // add-in items e.g. an input/button combo where the input
        // also has a flyout toggle for advanced searching.
        > .add-in > .add-in__right-item,
        > .add-in > .add-in__left-item {
            z-index: 2;
        }
    }

    .add-on__item {
        position: relative;

        &:hover,
        &:focus,
        &:active {
            z-index: 1;
        }
    }
}

.add-on__section {
    display: table-cell;
    vertical-align: middle;
    width: 1%;
}

table .add-on__section {
    width: auto;
}

.add-on__input-helper {
    @include font-size(11, true);
    background-color: $shade-2-color;
    border-radius: $brand-round +px;
    color: #fff;
    display: block;
    font-weight: bold;
    padding: $half-spacing-unit +px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(#000, .15);
    vertical-align: middle;
    white-space: nowrap;

    @include media-query(palm) {
        font-size: $mobile-font-size - 1 +px;
    }
}
