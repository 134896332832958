/*------------------------------------*\
    $RESET
\*------------------------------------*/
/**
 * A more considered reset; more of a restart...
 * As per: csswizardry.com/2011/10/reset-restarted
 */

/**
* Let's make the box model all nice, shall we...?
*/
* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
table,
th,
td,
caption,
figure,
hr {
    margin: 0;
    padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
    display: block;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

strong,
b,
mark {
    font-style: inherit;
    font-weight: bold;
}

em,
i,
cite,
q,
address,
dfn,
var {
    font-style: italic;
    font-weight: inherit;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted $shade-2-color;
    cursor: help;
}

ins {
    border-bottom: 1px solid;
}

a,
u,
ins {
    text-decoration: none;
}

del,
s {
    text-decoration: line-through;
}

img {
    border: 0;
    font-style: italic;
}

input,
select,
option,
optgroup,
textarea {
    font: inherit;
}


/**
 * Normalize `sup` and `sub` elements.
 */
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}


/**
 * Give form elements some cursor interactions...
 */
label,
button,
select,
option {
    cursor: pointer;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * Reset button defaults.
 */
button {
    background: none;
    border: 0;
    font: inherit;
    margin: 0;
    padding: 0;
    text-align: center;
    text-decoration: none;

    &:disabled {
        cursor: default;
    }
}
