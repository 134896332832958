.outlined-input {
  position: relative;
  padding: 2px 0px;

  .control {
    font-size: 12px;
    color: $dark-grey;
    display: block;
    width: 100%;
    padding: 14px;
    background-color: $white;
    background-image: none;
    transition: all 0.15s ease-in-out;
    border: 1px solid $silver;
    border-radius: 4px;
    font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
    font-weight: normal;
    display: inline-block;

    &:hover {
      border: 1px solid $dark-grey;
    }

    &:focus {
      border-color: $brand-color-dark;
      outline: none;
    }

    &:disabled {
      border-color: $med-grey-2;
      color: $med-grey-1;
    }
  }

  .shrinkable-label {
    position: absolute;
    font-size: 12px;
    top: -4px;
    padding: 16px 4px;
    left: 11px;
    transition: all 0.15s ease-in-out;
    font-weight: lighter;
    pointer-events: none;
    color: $dark-grey;
    background: transparent;
    font-weight: normal;
    margin: 0;
    line-height: 14px;

    &:disabled {
      color: $med-grey-1;
    }
  }

  .control:focus + .shrinkable-label,
  .filled + .shrinkable-label {
    color: $dark-grey;
    font-size: 10px;
    opacity: 1;
    background: $white;
    padding: 0px 5px;
    margin: 0;
    font-weight: normal;
  }

  .error.input-control-text + .shrinkable-label + .error-message {
    display: block;
  }

  .error.control {
    border: 1px solid $alert-red;
  }

  .error.control + .shrinkable-label,
  .validation-message {
    color: $alert-red;
  }

  .validation-message {
    padding-left: 14px;
    font-size: 10px;
  }

  .helper-message {
    padding-left: 14px;
    font-size: 10px;
  }

  .control:disabled + .shrinkable-label,
  .control:disabled + .shrinkable-label + .input-unit {
    color: $med-grey-1;
  }

  .input-unit {
    position: absolute;
    top: 10px;
    display: none;
    transition: 0.7s;

    &.left {
      left: 16px;
    }

    &.right {
      right: 12px;
    }
  }

  .control:focus + .shrinkable-label + .input-unit {
    display: block !important;
  }

  .filled + .shrinkable-label + .input-unit {
    display: block !important;
  }

  .pl-20 {
    padding-left: 20px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .icon-left {
    position: absolute;
    left: 15px;
    top: 11px;
  }
  .icon-right {
    position: absolute;
    right: 15px;
    top: 11px;
  }

  .required::after {
    content: "*";
    color: $red-failure-dark;
  }
}
