.search-all-bids {
  width: 100%;
  .search-grid {
    width: calc(100% - 48px);
    align .row:last-child {
      border-radius: 0 0 4px 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      border-bottom: 0px;
    }

    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-radius: 4px 4px 4px 4px;
  }

  .header-row {
    .header-cell:first-child {
      border-radius: 4px 0 0 0;
    }

    .header-cell:last-child {
      border-radius: 0 4px 0 0;
    }
  }

  .header-cell {
    border-bottom: 0px;
    user-select: none;

    &:hover {
      background: $site-background-color;
    }

    .resizable {
      resize: horizontal;
      overflow: auto;
      display: block;
      min-width: fit-content;
      &:hover {
        background-image: url("../../images/ResizeIcon.svg");
        background-repeat: no-repeat;
        background-position: bottom right;
      }
    }
  }

  .body-cell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .row {
    height: 48px;
    &:hover {
      background-color: $coconut-white;
      cursor: pointer;
    }
  }

  .status {
    height: 12px;
    width: 12px;
    border-radius: 9px;
    position: relative;
    top: 2px;
  }

  .filter-region {
    visibility: hidden;
  }

  .header-cell:nth-child(7):hover .filter-region {
    visibility: visible;
  }

  .header-cell:nth-child(8):hover .filter-region {
    visibility: visible;
  }

  .filter-region[aria-expanded="true"] {
    visibility: visible;
  }

  .sorted-header {
    background: $site-background-color;
  }
}
