/*------------------------------------*\
    $LOZENGES
\*------------------------------------*/
/**
 * Create pill- and lozenge-like runs of text,
 * use box classes to add/change color, e.g.:
 * 
   <p>This <span class="pill box--blue">here</span> is a pill!</p>
 * 
   <p>This <span class="loz box--blue">here</span> is also a lozenge!</p>
 * 
 * Pills have fully rounded ends, lozenges have only their corners rounded.
 * 
 * Demo: jsfiddle.net/inuitcss/N3pGm
 * 
 */
.pill {
    /**
     * Normally we’d use border-radius:100%; but instead here we just use an
     * overly large number; `border-radius:100%;` would create an oval on
     * non-square elements whereas we just want to round the ends of an element.
     */
    border-radius: 100px;
    display: inline-block;
    font-size: 8px;
    height: 10px;
    line-height: 10px;
    text-align: center;
}

.loz {
    @extend .pill;
    border-radius: $brand-round +px;
}

.loz--large {
    @extend .loz;
    padding: ($line-height-ratio * .25) +em;
}

.pill--large {
    @extend .pill;
    padding-top: ($line-height-ratio * .09) +em;
    padding-bottom: ($line-height-ratio * .09) +em;
    padding-left: ($line-height-ratio * .55) +em;
    padding-right: ($line-height-ratio * .55) +em;
}
