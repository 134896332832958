/*------------------------------------*\
    $BLOCK-LIST
\*------------------------------------*/
/**
 * Create big blocky lists of content, e.g.:
 *
   <ul class=block-list>
      <li>Foo</li>
      <li>Bar</li>
      <li>Baz</li>
      <li><a href=# class=block-list__link>Foo Bar Baz</a></li>
   </ul>
 *
 * Extend this object in your theme stylesheet.
 *
 * Demo: jsfiddle.net/inuitcss/hR57q
 *
 */
.block-list {
    > li {
        border-bottom: 1px solid $default-ui-color;
        padding: $half-spacing-unit +px;
        &.box--red-medium {
			background-color: $red-medium;
            border: 1px solid $red-medium;
            .block-list__link {
                &.active,
                &:hover {
                    background-color: $red-medium;
                }
            }
        }
        &.box--orange-medium  {
			background-color: $orange-medium;
            border: 1px solid $orange-medium;
            .block-list__link {
                &.active,
                &:hover {
                    background-color: $orange-medium;
                }
            }
        }
        &.box--yellow-medium {
			background-color: $yellow-medium;
            border: 1px solid $yellow-medium;
            .block-list__link {
                &.active,
                &:hover {
                    background-color: $yellow-medium;
                }
            }
        }

        &:last-child {
            border-bottom-width: 0;
        }
    }
}

.block-list__link {
    box-sizing: content-box;
    cursor: pointer;
    display: block;
    margin: -$half-spacing-unit +px;
    min-width: 100%;
    padding: $half-spacing-unit +px;
    text-align: left;
    text-decoration: underline;

    .highlight {
        color: #000;
        font-weight: bold;
    }

    &:hover,
    &:active,
    &.active {
        background: $shade-4-color;
    }

    &.disabled {

        &,
        &:hover,
        &:active,
        &.active {
            background: #fff;
            color: $shade-2-color;
            cursor: default;
        }
    }

    &:focus {
        outline-offset: 0;
        outline: 2px solid rgba($brand-color,.35);
    }
}

/**
 * Extending this to account for multiple levels
 * inside the block-list.
 *
 */
.block-list ul {
    list-style: none;
    margin: 0;
}

.block-list--stripped {
  li:nth-of-type(odd) {
    background: $shade-4-color;
  }
}


.block-list-new {
    > li {
        border-bottom: 1px solid $shade-6-color;
        margin-left: $half-spacing-unit +px;
        margin-right: $half-spacing-unit +px;
        padding-top: $default-spacing-unit +px;
        padding-bottom: $default-spacing-unit +px;
    }
}