/*------------------------------------*\
    $CUSTOM-SELECT
\*------------------------------------*/

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    @include font-size($default-font-size, true);
    background-color: tint($shade-4-color, 60%);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjBweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSItMjIzIC0yMzIgMjAgMTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgLTIyMyAtMjMyIDIwIDEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNLTIxMy0yMzJsLTMuMyw0LjdoNi43TC0yMTMtMjMyTC0yMTMtMjMyeiBNLTIxMy0yMjBsMy4zLTQuN2gtNi43TC0yMTMtMjIwTC0yMTMtMjIweiIvPg0KPC9zdmc+DQo=);
    background-position: 100% center;
    background-repeat: no-repeat;
    border-radius: $brand-round +px;
    border: 1px solid $default-ui-color;
    color: $default-text-color;
    font-family: inherit;
    font-weight: 700;
    height: $default-spacing-unit * 2 +px;
    padding: $half-spacing-unit - 1 +px $default-spacing-unit +px $half-spacing-unit - 1 +px $half-spacing-unit - 1 +px;

    &:active,
    &:active:hover {
        box-shadow: inset 0 3px 3px rgba(0,0,0,.125), inset 0 0 0 16px rgba(#000,.075);
    }

    &:focus {
        outline-offset: 0;
        outline: 2px solid rgba($brand-color,.35);
    }

    &:hover {
        border: 1px solid shade($default-ui-color,10%);
        box-shadow: inset 0 0 0 16px rgba(#000,.05);
    }

    &[disabled] {
        box-shadow: none;
        cursor: not-allowed;
        opacity: .5;
    }

    &.error {
        border-color: $red;
        color: $default-text-color;
    }

    /*
     * Repeated styles as .error for IE9
     */
    &:invalid {
        border-color: $red;
    }

    @include media-query(palm) {
        font-size: $mobile-font-size +px;
        height: 37px;

        &:active,
        &:active:hover {
            box-shadow: inset 0 3px 3px rgba(0,0,0,.125), inset 0 0 0 18px rgba(#000,.075);
        }

        &:hover {
            box-shadow: inset 0 0 0 18px rgba(#000,.05);
        }
    }
}

select::-ms-expand {
    display: none;
}

.lt-ie10 {
    select {
        padding: 7px;
    }
}
