.admin {
  .page-width {
    width: 660px;
    margin-left: auto;
    margin-right: auto;
  }

  .wrapper-class {
    margin-bottom: 8px;
  }

  .editor-class {
    border: 1px solid $med-grey-1;
    padding-left: 8px;
    padding-right: 8px;
  }

  .toolbar-class {
    border: 1px solid $med-grey-1;
  }

  .validation-message {
    color: $alert-red;
  }
}
