/*------------------------------------*\
    $MIXINS
\*------------------------------------*/
/**
 * Create a fully formed type style (sizing and vertical rhythm) by passing in a
 * single value, e.g.:
 *
   `@include font-size(10);`
 *
 * Thanks to @redclov3r for the `line-height` Sass:
 * twitter.com/redclov3r/status/250301539321798657
 */
@mixin font-size($font-size, $line-height:true) {
    font-size: $font-size +px;
    @if $line-height == true{
        line-height: ceil($font-size / $default-line-height) * ($default-line-height / $font-size);
    }
}

/**
 * Force overly long spans of text to truncate, e.g.:
 *
   `@include truncate(100%);`
 *
 * Where `$truncation-boundary` is a united measurement.
 */
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/**
 * Single side border-radius
 */
@mixin border-top-radius($radius) {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}

/**
 * Media query mixin.
 *
 * It’s not great practice to define solid breakpoints up-front, preferring to
 * modify your design when it needs it, rather than assuming you’ll want a
 * change at ‘mobile’. However, as inuit.css is required to take a hands off
 * approach to design decisions, this is the closest we can get to baked-in
 * responsiveness. It’s flexible enough to allow you to set your own breakpoints
 * but solid enough to be frameworkified.
 *
 * We define some broad breakpoints in our vars file that are picked up here
 * for use in a simple media query mixin. Our options are:
 *
 * palm
 * lap
 * lap-and-up
 * portable
 * desk
 *
 * Not using a media query will, naturally, serve styles to all devices.
 *
 * `@include media-query(palm) { [styles here] }`
 *
 * We work out your end points for you:
 */

@mixin media-query($media-query) {

    @if $media-query == palm{
        @media only screen and (max-width:$palm-end) { @content; }
    }

    @if $media-query == lap{
        @media only screen and (min-width:$lap-start) and (max-width:$lap-end) { @content; }
    }

    @if $media-query == lap-and-up{
        @media only screen and (min-width:$lap-start) { @content; }
    }

    @if $media-query == portable{
        @media only screen and (max-width:$lap-end) { @content; }
    }

    @if $media-query == desk{
        @media only screen and (min-width:$desk-start) { @content; }
    }
}

/*
 * Extending Linear gradient Background color
*/
@mixin linearGradientBackground ($color) {
    background: linear-gradient(
        to left bottom,
        lighten($shade-3-color, 10) 0%,
        lighten($shade-3-color, 10) 25%,
        lighten($color, 35) 25%,
        lighten($color, 35) 50%,
        lighten($shade-3-color, 10) 50%,
        lighten($shade-3-color, 10) 75%,
        lighten($color, 35) 75%
    );
}