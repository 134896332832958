/*------------------------------------*\
    $LISTS
\*------------------------------------*/
/**
 * Remove vertical spacing from nested lists.
 */
li {

    > ul,
    > ol {
        margin-bottom: 0;
    }
}

/**
 * Classes for lists rather than style being applied to the element.
 */
ol,
ul,
dl {
    list-style: none;
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once, here.
 */

.list {
    list-style-type: disc;
    margin-left: 2 * $default-spacing-unit +px;
}

ol.list {
    list-style-type: decimal;
}

/**
 * List Transition
 * Adds a transition when adding or removing an object from a list by moving the object 50 pixels to the left and fading it in.
 *
 * Base transition class on something you want transitioned. This will hide the object and position it 50 pixels to the right.
<ul>
  <li class="list-transition active"></li>
</ul>

* Remove Transition Effect to cause the list object to show
<ul>
  <li class="list-transition"></li>
</ul>
*/

.list-transition {
    transition: all .5s;
    left: 0;
    position: relative;

    &.active {
        opacity: 0;
        left: 50px;
    }
}

/**
 * Labeled lists pair labels with associated values in a list that is formated in columns
 * for vertical alignment, e.g.:
 *
   <ul class="labeled-list">
      <li class="labeled-list__row">
          <span class="labeled-list__cell label">Name</span>
          <span class="labeled-list__cell">555-555-5555</span>
      </li>
      <li class="labeled-list__row">
          <span class="labeled-list__cell label">Phone</span>
          <span class="labeled-list__cell">555-555-5555</span>
      </li>
   </ul>
 *
 */
.labeled-list {
    display: table;
}

.labeled-list__row {
    display: table-row;
}

.labeled-list__cell {
    display: table-cell;
}

dt.labeled-list__cell {
    font-weight: bold;
    padding-right: $default-spacing-unit +px;
}

/**
 * Rather than limiting lists with extra space to forms,
 * extend the form-fields list to any type of list.
 *
   <ul class="spacious-list">
      <li>
          Content
      </li>
      <li>
          Content
      </li>
   </ul>
 *
 */
.spacious-list {
    @extend %form-fields;
}
