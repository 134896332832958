.message-input-container {
  padding: 7px 0px;
  max-height: 50px;
  overflow: auto;
  margin-right: 8px;
  width: 100%;
  .message-input {
    .message-input__highlighter {
      strong {
        background-color: #bef2c2;
      }
    }
    .message-input__input {
      outline: none;
      border: none;
      box-shadow: none !important;
      line-height: 18px;
    }
  }
}

.message-input__suggestions {
  position: absolute !important;
  top: -12px !important;
  left: 0px !important;
  width: 100%;
}

.message-input__suggestions__item__highlight {
  font-weight: normal;
}

.message-input__suggestions__list {
  max-height: 184px;
  overflow-y: auto;
  overflow-x: hidden;
}

.message-input__suggestions__list::-webkit-scrollbar,
.message-input-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.message-input__suggestions__list::-webkit-scrollbar-thumb,
.message-input-container::-webkit-scrollbar-thumb {
  background: $shade-3-color;
  border-radius: 3.5px;
}
.message-input__suggestions__list::-webkit-scrollbar-track,
.message-input-container::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 0px;
}
