/*------------------------------------*\
    $STATE
\*------------------------------------*/

/* Mixiing for display status on diffrent devices */
@mixin hidden-setup($namespace: "") {
    .#{$namespace}hidden {
        display: none !important;
        visibility: hidden !important;
    }

    .#{$namespace}invisible {
        visibility: hidden !important;
    }
}

@include hidden-setup();

@if $responsive == true {

    @include media-query(palm) {
        @include hidden-setup("palm-");
    }

    @include media-query(lap) {
        @include hidden-setup("lap-");
    }

    @include media-query(lap-and-up) {
        @include hidden-setup("lap-and-up-");
    }

    @include media-query(portable) {
        @include hidden-setup("portable-");
    }

    @include media-query(desk) {
        @include hidden-setup("desk-");
    }
}

/**
 * Hide content by resorting to `display:none;`
 */
.hidden {
    display: none !important;
    visibility: hidden !important;
}


/**
 * Hide visually and from screenreaders, but maintain layout
 */
.invisible {
    visibility: hidden;
}

/**
 * Add a help cursor to any element that gives the user extra information on
 * `:hover`.
 */
.informative {
    cursor: help;
}

 /**
 * Changes cursor to pointer. Great for non-anchor
 * action icons and elements.
 */
.pointer {
    cursor: pointer;
}


/**
 * Changes cursor to default arrow. Use on elements
 * contained within actionable elements that are not
 * themselves actionable
 */
.non-actionable {
    cursor: default;
}

/**
 * Removes the ability to select an element. Useful
 * when the highlight or select of the element jumps
 * all over the page.
 */
.non-selectable {
    user-select: none;
}

/**
 * Changes cursor to move icon. Use on elements
 * that can be moved with the mouse
 * themselves actionable
 */
.movable {
    cursor: move;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: grab;
}

.moving {
    cursor: move!important;
    cursor: -moz-grabbing!important;
    cursor: -webkit-grabbing!important;
    cursor: grabbing!important;
}

/**
 * Set an element's color to red.
 */
.error {
    color: $red;
}

/**
 * Set an element's color to green.
 */
.success {
    color: $green;
}

/**
 * Set an element's color to yellow.
 */
.warning {
    color: $orange;
}

.floating {
    box-shadow: $drop-shadow;
}

.scrollable {
    overflow-y: auto !important;
}

.new {
    box-shadow: 0 0 0 2px $brand-secondary-color !important;
}