/*------------------------------------*\
    $FORM STEPS PROGRESS BAR
\*------------------------------------*/

body {
  counter-reset: step;
  padding: 0px;
}

#progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  position: relative;
  text-align: center;
}

#progressbar li.current {
  color: black;
}

#progressbar li.current:before {
  color: black;
  border: 2px solid #69c764;
}


/*progress bar circles*/ 
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 28px;
  display: block;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  border: 2px solid #ddd;
  text-align: center;
  background-color: #eff2fa;
  z-index: 99;
  position: relative;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #ddd;
  position: absolute;
  left: -50%;
  top: 15px;
  z-index: 1;
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
#progressbar li.active:before {
  border-color: #69c764;
}

#progressbar li.active + li:after {
  background-color: #69c764;
}

#progressbar li.active + li:before {
  background-color: #eff2fa;
}