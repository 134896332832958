/*------------------------------------*\
    $IMAGES
\*------------------------------------*/
img {
    height: auto;
    max-width: 100%;
}

figure > img {
    display: block;
}
