.active-bids-grid-view {
  .bids-grid {
    align .row:last-child {
      border-radius: 0 0 4px 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      border-bottom: 0px;
    }

    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-radius: 4px 4px 4px 4px;
  }

  .header-row {
    height: 50px;
    .header-cell:first-child {
      border-radius: 4px 0 0 0;
      padding-left: 24px;
    }

    .header-cell:last-child {
      border-radius: 0 4px 0 0;
      padding-right: 24px;
    }
  }

  .header-cell {
    border-bottom: 0px;
    user-select: none;

    &:hover {
      background: $site-background-color;
    }
  }

  .row {
    height: 48px;
    &:hover {
      background-color: $coconut-white;
      cursor: pointer;
    }

    .body-cell:first-child {
      padding-left: 24px;
    }

    .body-cell:last-child {
      padding-right: 24px;
    }
  }

  .sorted-header {
    background: $site-background-color;
  }

  .status {
    height: 12px;
    width: 12px;
    border-radius: 9px;
    position: relative;
    top: 2px;
  }

  .team-member {
    img,
    span {
      border: 1px solid $green;
    }
  }

  .team-member,
  .subscribers {
    img,
    span {
      height: 26px !important;
      width: 26px !important;
      font-size: 10px !important;
    }
  }

  .bid-tags {
    font-size: 12px;
    line-height: 14px;
    padding: 1px 6px;
    height: auto;
    margin-right: 4px;
    border-radius: 4px;
  }
}
