#active-bids-lists {
  .bids-list {
    flex: 0 0 calc(25% - 9px);
    min-width: 0;
    background-color: $card-list-back-color;
    height: fit-content;
    padding-bottom: 12px;

    .bids-list-title {
      background-color: $card-list-back-color;
      border-radius: 4px;
      position: sticky;
      top: 0;
      z-index: 1;

      &::before {
        border-radius: 4px 4px 0 0;
        content: " ";
        display: block;
        height: 8px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      &#pending-bids-list-title::before {
        background-color: #015c92;
      }

      &#in-progress-bids-list-title::before {
        background-color: #53a6d8;
      }

      &#submitted-bids-list-title::before {
        background-color: #534582;
      }

      &#feedback-bids-list-title::before {
        background-color: #69c764;
      }
    }

    .bid {
      background-color: #e2e8f3;
      padding: 0 24px 8px;
      cursor: pointer;
      // Hides background revealed by border radius of .bids-list-title
      // and adds extra padding to show .bid > .card's entire box shadow
      &:first-of-type {
        margin-top: -3px;
        padding-top: 5px;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
        padding-bottom: 6px;
      }

      .bid-card {
        display: grid;
        grid-template-areas: "bid-name bid-name" "shipper-name shipper-name" "bid-avatars bid-avatars" "bid-deadline-date bid-deadline-date" "bid-lane-count bid-lane-count" "bid-last-status-change-time bid-tag";
        grid-template-columns: 45% 55%;
        grid-template-rows: auto;
        padding: 12px 16px;
      }

      .shipper-name {
        grid-area: shipper-name;
        line-height: 17px;
      }

      .bid-name {
        grid-area: bid-name;
        line-height: 14px;
        font-size: 12px;
      }

      .bid-avatars {
        font-family: $univers-cond;
        font-size: 8px;
        grid-area: bid-avatars;
        line-height: 10px;
        .team-member {
          img,
          span {
            border: 1px solid $green;
          }
        }
      }

      .bid-deadline-date {
        grid-area: bid-deadline-date;
        font-size: 12px;
        line-height: 14px;
      }

      .bid-tag {
        grid-area: bid-tag;
        .pill {
          line-height: 12px;
          height: 12px;
        }
      }

      .bid-lane-count {
        grid-area: bid-lane-count;
        font-size: 12px;
        line-height: 14px;
      }

      .bid-last-status-change-time {
        grid-area: bid-last-status-change-time;
      }
    }
    .card-container {
      max-height: calc(100vh - 200px);
      overflow: auto;
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.5);
      }
      &::-webkit-scrollbar {
        width: 9px;
      }
    }
    .bid-fetching {
      flex-basis: 76px;
    }
  }
}
