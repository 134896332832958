/*------------------------------------*\
    $FORMS
\*------------------------------------*/
/**
 *
 * Demo: jsfiddle.net/inuitcss/MhHHU
 *
 */
/**
 * Text inputs
 *
 * Instead of a `[type]` selector for each kind of form input, we just use a
 * class to target any/every one.
 *
 */

/* We use this to get basic styling on all basic form elements */
.text-input,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    @include font-size($default-font-size, true);
    background-color: #fff;
    border-radius: $brand-round +px;
    border: 1px solid $default-ui-color;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    color: $default-text-color;
    display: inline-block;
    font-family: inherit;
    margin: 0;
    min-height: $default-spacing-unit * 2 +px;
    padding: $half-spacing-unit - 1 +px;
    transition: all .15s linear;

    &:focus,
    &.active {
        outline-offset: 0;
        outline: 2px solid rgba($brand-color,.35);
    }

    &[readonly],
    &[disabled] {
        background: $shade-4-color;
    }

    &[disabled] {
        cursor: not-allowed;
        color: tint($default-text-color, 40%);
    }

    &.error {
        background-color: mix(#fff, $red, 90%);
        border-color: $red;
        color: $default-text-color;
    }

    /*
     * Repeated styles as .error for IE9
     */
    &:invalid {
        background-color: mix(#fff, $red, 90%);
        border-color: $red;
    }

    @include media-query(palm) {
        font-size: $mobile-font-size +px;
    }
}

/*
 * Limitations on user-control over textarea
 */
textarea {
    overflow: auto;
    resize: vertical;
    vertical-align: top;
    cursor: text;
}

/**
 * Extra help text displayed after a field.
 *
   <label for=email>Email: </label>
   <input type=email class=text-input id=email>
   <small class=extra-help>.edu emails only</small>
 *
 */
.extra-help {
    @extend .milli;
    padding: $default-font-size / 2 +px 0;
}

.text-input + .extra-help {
    margin-top: $default-font-size / 4 +px;
}

/**
 * Custom styling for fieldsets and legends
 */
fieldset {
    border: 0;
    min-width: 0;
    padding: 0;
}

legend {
    @extend .epsilon;
    @extend .mb-8;
    color: $default-text-color;
    font-weight: bold;
    text-transform: uppercase;
}

/**
 * Group sets of form fields in a list, e.g.:
 *
   <ul class=form-fields>
       <li>
           <label />
           <input />
       </li>
       <li>
           <label />
           <select />
       </li>
   </ul>
 *
 */
%form-fields,
.form-fields {
    list-style: none;
    margin: 0;

    > li {
        margin-bottom: $default-spacing-unit +px;
        position: relative;
    }

    > li:last-child {
        margin-bottom: 0;
    }
}

/**
 * Labels
 *
 * Define a `.label` class as well as a `label` element. This means we can apply
 * label-like styling to meta-labels for groups of options where a `label`
 * element is not suitable, e.g.:
 *
   <li>
       <span class=label>Select an option below: </span>
       <ul class="multi-list  four-cols">
           <li>
               <input /> <label />
           </li>
           <li>
               <input /> <label />
           </li>
       </ul>
   </li>
 *
 */
.label {
    font-weight: bold;

    &.block {
        margin-bottom: 2px;
    }

    & > .label__text {
        margin-bottom: 2px;
    }
}

/*
 * Adds an asterisks to an element, typically a label:
 *
   <label class="label block required">Name</label>
   <input type="text" class="text-input" />
 *
 */
.required:after {
    @include font-size($default-font-size, false);
    color: $red;
    content: " *";
    line-height: 0;
}

.required-before:before {
    @include font-size($default-font-size, false);
    color: $red;
    content: " *";
    line-height: 0;
}

/*
 * Groups of checkboxes and radios, e.g.:
 *
   <li>
       <ul class=check-list>
           <li>
               <input /> <label />
           </li>
           <li>
               <input /> <label />
           </li>
       </ul>
   </li>
 *
 */

.check-list {
    list-style: none;
    margin: 0;

    li {
        margin-bottom: .5em;
        padding-left: $default-font-size * 1.666666667 +px;
        text-indent: - $default-font-size * 1.583333333 +px;

        // To handle check-lists inside of a check-list
        > .check-list {
            margin-top: .5em;
        }
    }
}

/*
 * Automatically add margin to the right of
 * checkbox and radio labels.
 */
input[type="checkbox"] + label,
input[type="radio"] + label {
    margin-right: $default-spacing-unit +px;
}

/*
 * Adds a disabled "look" to labels that
 * immediately follow checkboxes.
 */
input[type="checkbox"]:disabled + label,
input[type="radio"]:disabled + label {
    color: $shade-3-color;
    cursor: default;
}

/*
 * Custom colorful checkbox input element new UI
*/
.custom-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    // Box.
    & + label:before {
        content: '';
        margin-left: 4px;
        margin-right: 7px;
        display: inline-block;
        vertical-align: text-top;
        width: 14px;
        height: 14px;
        border-radius: 2px;
    }

    // Disabled state label.
    &:disabled + label {
        cursor: not-allowed !important;
    }

    // Disabled box.
    &:disabled + label:before {
        background-color: $shade-3-color !important;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 3px;
        background-color: transparent;
        width: 10px;
        height: 5px;
        border-right: 2px solid white;
        border-top: 2px solid white;
        transform: rotate(125deg);
    }

    &.checkbox--red {
        + label:before {
            background-color: $red;
        }
    }

    &.checkbox--blue {
        + label:before {
            background-color: $blue;
        }
    }

    &.checkbox--green {
        + label:before {
            background-color: $green;
        }
    }

    &.checkbox--yellow {
        + label:before {
            background-color: $yellow;
        }

        &:checked + label:after {
            border-right: 2px solid black;
            border-top: 2px solid black;
        }
    }
}

.custom-radio {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    opacity: 0;

    & + label {
        display: inline-block;
        vertical-align: middle;
        padding-left: 18px;
        position: relative;

        &:before, &:after {
            content: "";
            position: absolute;
            border-radius: 100px;
            background-color: transparent;
        }

        &:before {
            width: 14px;
            height: 14px;
            background-color: transparent;
            left: 0;
            top: 0;
            box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.8);
        }

        &:after {
            width: 6px;
            height: 6px;
            left: 4px;
            top: 4px;
        }
    }

    &:checked + label {
        &:after {
            background-color: #000;
        }
    }

    &.radio--green:checked {
        & + label {
            &:before {
                background-color: #27d434;
            }
        }
    }

    &.radio--blue:checked {
        & + label {
            &:before {
                background-color: #0088d1;
            }
        }
    }

    &.radio--yellow:checked {
        & + label {
            &:before {
                background-color: #ffe200;
            }
        }
    }

    &.radio--red:checked {
        & + label {
            &:before {
                background-color: #ff572b;
            }
        }
    }
}

.no-text-indent {
    text-indent: 0;
}