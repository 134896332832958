/*------------------------------------*\
    $PULL
\*------------------------------------*/
/**
 * Pull classes, to move grid items over to the left by certain amounts.
 */
.pull {
    position: relative;
}

/**
 * Halves
 */
.pull--one-half             { @extend .pull; right: 50%; }

/**
* Thirds
*/
.pull--one-third            { @extend .pull; right: 33.333%; }
.pull--two-thirds           { @extend .pull; right: 66.666%; }

/**
* Quarters
*/
.pull--one-quarter          { @extend .pull; right: 25%; }
.pull--two-quarters         { @extend .pull--one-half; }
.pull--three-quarters       { @extend .pull; right: 75%; }

/**
* Fifths
*/
.pull--one-fifth            { @extend .pull; right: 20%; }
.pull--two-fifths           { @extend .pull; right: 40%; }
.pull--three-fifths         { @extend .pull; right: 60%; }
.pull--four-fifths          { @extend .pull; right: 80%; }

/**
* Sixths
*/
.pull--one-sixth            { @extend .pull; right: 16.666%; }
.pull--two-sixths           { @extend .pull--one-third; }
.pull--three-sixths         { @extend .pull--one-half; }
.pull--four-sixths          { @extend .pull--two-thirds; }
.pull--five-sixths          { @extend .pull; right: 83.333%; }

/**
* Eighths
*/
.pull--one-eighth           { @extend .pull; right: 12.5%; }
.pull--two-eighths          { @extend .pull--one-quarter; }
.pull--three-eighths        { @extend .pull; right: 37.5%; }
.pull--four-eighths         { @extend .pull--one-half; }
.pull--five-eighths         { @extend .pull; right: 62.5%; }
.pull--six-eighths          { @extend .pull--three-quarters; }
.pull--seven-eighths        { @extend .pull; right: 87.5%; }

/**
* Tenths
*/
.pull--one-tenth            { @extend .pull; right: 10%; }
.pull--two-tenths           { @extend .pull--one-fifth; }
.pull--three-tenths         { @extend .pull; right: 30%; }
.pull--four-tenths          { @extend .pull--two-fifths; }
.pull--five-tenths          { @extend .pull; right: 50%; }
.pull--six-tenths           { @extend .pull--three-fifths; }
.pull--seven-tenths         { @extend .pull; right: 70%; }
.pull--eight-tenths         { @extend .pull--four-fifths; }
.pull--nine-tenths          { @extend .pull; right: 90%; }

/**
* Twelfths
*/
.pull--one-twelfth          { @extend .pull; right: 8.333%; }
.pull--two-twelfths         { @extend .pull--one-sixth; }
.pull--three-twelfths       { @extend .pull--one-quarter; }
.pull--four-twelfths        { @extend .pull--one-third; }
.pull--five-twelfths        { @extend .pull; right: 41.666%; }
.pull--six-twelfths         { @extend .pull--one-half; }
.pull--seven-twelfths       { @extend .pull; right: 58.333%; }
.pull--eight-twelfths       { @extend .pull--two-thirds; }
.pull--nine-twelfths        { @extend .pull--three-quarters; }
.pull--ten-twelfths         { @extend .pull--five-sixths; }
.pull--eleven-twelfths      { @extend .pull; right: 91.666%; }
