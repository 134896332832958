.auto-complete {
  height: 50px;
  position: relative;
  padding: 2px 0px;
  label.auto-complete-label {
    position: absolute;
    font-size: 12px;
    top: 2px;
    padding: 9px 0;
    left: 14px;
    transition: all 0.15s ease-in-out;
    -o-transition: all ease-in-out 0.15s;
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all ease-in-out 0.15s;
    font-weight: lighter;
    pointer-events: none;
    color: #343434;
    background: transparent;
    font-weight: normal;
    margin: 0;
    line-height: 14px;
  }
  label.disabled {
    color: #adafb2;
  }
  .shrink + .auto-complete-label {
    font-size: 10px;
    opacity: 1;
    background: #ffffff;
    padding: 0 4px 0 4px;
    left: 11px;
    top: -4px;
    margin: 0;
  }
  &.mini {
    label.auto-complete-label {
      top: 0px;
    }
    .shrink + .auto-complete-label {
      top: -5px;
    }
    .auto-complete__indicators {
      svg {
        top: -5px !important;
      }
    }
  }

  label.error {
    color: #790000 !important;
  }
  .no-option-container {
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      color: #616161;
      margin: 0;
    }
    .spinner {
      animation-name: spin;
      animation-duration: 5000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .validation-message {
    font-size: 10px;
    color: #790000;
    padding: 2px 0px 0px 14px;
    line-height: 12px;
  }
  .multi-select-option {
    padding: 0px 15px;
    font-size: 12px;
    line-height: 15px;
    color: #343434;
    &:hover {
      background-color: #f5f5f6;
    }
    &.disabled {
      color: $shade-2-color;
    }
  }
  .auto-complete__multi-value::after {
    content: ",";
  }
  .auto-complete__multi-value:last-of-type::after {
    content: "";
  }
  .auto-complete__value-container {
    padding-left: 14px;
  }
  .minimum-char {
    height: auto;
    justify-content: left;
    padding-left: 4px;
  }
  .icon-right {
    position: absolute;
    right: 8px;
    top: 12px;
  }
  .multi-select-value {
    position: absolute;
    top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 25px);
    pointer-events: none;
    padding-left: 14px;
  }

  .required::after {
    content: "*";
    color: $red-failure-dark;
  }
}

/* The checkbox-container */
.checkbox-container {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 8px;
  left: 0;
  height: 12.6px;
  width: 12.6px;
  border: 1px solid #adafb2;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #0088d1;
  border: 2px solid #0088d1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 2px;
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

span.check-text {
  position: relative;
  top: -8px;
}

.custom-option {
  font-size: 12px;
  padding: 7px 14px;

  &:hover {
    background-color: $shade-7-color;
  }

  .hyperlink {
    color: $cobalt-blue-60;
    font-weight: 500;
    line-height: 18px;
  }
}
