/*------------------------------------*\
    $MODAL
\*------------------------------------*/

.modal {
  background-color: #fff;
  min-height: 206px;
  max-width: 494px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

.modal--auto-min-height {
  min-height: auto;
}

.modal__close-button {
  float: right;
  margin: -5px -5px 0 0;

  path {
    fill: #000;
  }
}

.modal__title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 24px;
  padding-left: 24px;
  padding-top: 24px;
}

.modal__footer {
  margin-top: $default-spacing-unit + $half-spacing-unit + px;
  text-align: right;
}

.modal__title_light {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 24px;
  font-weight: normal;
  font-family: "UniversLTPro-57Cond";
  line-height: 32px;
  .modal__close-button {
    margin: 8px 24px;
  }
}

.modal__large {
  min-width: 540px;
}

.modal__x-large {
  max-width: 790px;
  width: 790px;
}
