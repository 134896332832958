/*------------------------------------*\
    $FUNCTIONS
\*------------------------------------*/
/// Slightly lighten a color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}