/*------------------------------------*\
    $DISPLAY
\*------------------------------------*/
/**
 * Selectors for low-level display traits
 */
@mixin display-setup($namespace: "") {
    .#{$namespace}inline {
        display: inline;
    }

    .#{$namespace}inline-block {
        display: inline-block;
    }

    .#{$namespace}block {
        display: block;
    }
}

@include display-setup();

@if $responsive == true {

    @include media-query(palm) {
        @include display-setup("palm-");
    }

    @include media-query(lap) {
        @include display-setup("lap-");
    }

    @include media-query(lap-and-up) {
        @include display-setup("lap-and-up-");
    }

    @include media-query(portable) {
        @include display-setup("portable-");
    }

    @include media-query(desk) {
        @include display-setup("desk-");
    }
}
