#home-page {
  height: 100%;
  min-width: 1260px;
  max-width: 1380px;
  margin: 0px auto;

  .react-tabs__tab {
    padding: 5px 22px 5px 22px;
    margin: 0;
    background: #fff;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  }

  .react-tabs__tab:first-child {
    border-radius: 4px 0 0 4px;
  }

  .react-tabs__tab:last-child {
    border-radius: 0 4px 4px 0px;
  }

  .react-tabs__tab--selected {
    background-color: #394350;
  }

  .react-tabs__tab--selected:after {
    background: none;
  }

  .filter-menu {
    padding: 5px 10px 4px 10px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    svg {
      position: relative;
      top: 2px;
    }
    &:disabled {
      cursor: default;
    }
  }

  .filter-popper {
    width: 244px;
    padding: 16px;
    text-align: left;
    .filter-control {
      display: block;
      position: relative;
      input[type="checkbox"],
      input[type="radio"] {
        margin: 8px 8px 0px 0px;
      }
      span {
        position: absolute;
        margin-top: 7px;
      }
    }
    .clear-filter {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  .active-bids .auto-complete {
    width: 180px;
    height: auto !important;
    top: 2px;
  }

  .active-bids .auto-complete__menu {
    z-index: 99;
  }
}
