/*------------------------------------*\
    $HEADINGS
\*------------------------------------*/
/**
 * As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
 *
 * When we define a heading we also define a corresponding class to go with it.
 * This allows us to apply, say, `class=alpha` to a `h3`; a double-stranded
 * heading hierarchy.
 */
h1      { @include font-size($h1-size); }
h2      { @include font-size($h2-size); }
h3      { @include font-size($h3-size); }
h4      { @include font-size($h4-size); }
h5      { @include font-size($h5-size); }
h6      { @include font-size($h6-size); }
small   { @include font-size($milli-size); }

@mixin type-setup($namespace: "") {
    .#{$namespace}giga      { @include font-size($giga-size); }
    .#{$namespace}mega      { @include font-size($mega-size); }
    .#{$namespace}kilo      { @include font-size($kilo-size); }
    .#{$namespace}alpha     { @include font-size($h1-size); }
    .#{$namespace}beta      { @include font-size($h2-size); }
    .#{$namespace}gamma     { @include font-size($h3-size); }
    .#{$namespace}delta     { @include font-size($h4-size); }
    .#{$namespace}epsilon   { @include font-size($h5-size); }
    .#{$namespace}zeta      { @include font-size($h6-size); }
    .#{$namespace}milli     { @include font-size($milli-size); }
}

@include type-setup();

@if $responsive == true {
    @include media-query(palm)          { @include type-setup("palm-"); }
    @include media-query(lap)           { @include type-setup("lap-"); }
    @include media-query(lap-and-up)    { @include type-setup("lap-and-up-"); }
    @include media-query(portable)      { @include type-setup("portable-"); }
    @include media-query(desk)          { @include type-setup("desk-"); }
}

/**
 * Heading & body copy styles
 */

@mixin alphaHeading() {
    font-family: $univers-bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
}

@mixin betaHeading() {
    font-family: $univers-cond;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-transform: uppercase;
}

@mixin gammaHeading() {
    font-family: $univers-light;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
}

@mixin deltaHeading() {
    font-family: $univers-bold;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
}

@mixin epsilonHeading() {
    font-family: $univers-cond;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-transform: uppercase;
}

.alpha-heading {
    @include alphaHeading();
}

.beta-heading {
    @include betaHeading();
}

.gamma-heading {
    @include gammaHeading();
}

.delta-heading {
    @include deltaHeading();
}

.epsilon-heading {
    @include epsilonHeading();
}

@mixin alphaBodyCopy() {
    font-size: $alpha-body-copy-size;
    line-height: $alpha-body-copy-line-height;
}

@mixin betaBodyCopy() {
    font-size: $beta-body-copy-size;
    line-height: $beta-body-copy-line-height;
}

@mixin gammaBodyCopy() {
    font-size: $gamma-body-copy-size;
    line-height: $gamma-body-copy-line-height;
}

@mixin deltaBodyCopy() {
    font-size: $delta-body-copy-size;
    line-height: $delta-body-copy-line-height;
}

.alpha-body-copy {
    @include alphaBodyCopy();
}

.beta-body-copy {
    @include betaBodyCopy();
}

.gamma-body-copy {
    @include gammaBodyCopy();
}

.delta-body-copy {
    @include deltaBodyCopy();
}