#nav {
  background-color: #293342;
  flex: 0 0 60px;

  .nav-item {
    color: #fff;
    cursor: pointer;
    font-size: 10px;
    height: 60px;
    line-height: 11px;
    width: 60px;

    span {
      color: #fff;
      outline: none;
      text-decoration: none;
      z-index: 9999;
    }

    svg {
      fill: #fff;
      height: 20px;
      width: 20px;
    }
  }

  .selected-item {
    background-color: #394350;
    font-weight: bold;
  }

  .unread-notification {
    top: 7px;
    right: 5px;
    background-color: #dd3535;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    width: 16px;
    height: 16px;
    line-height: 14px;
  }

  @media (pointer: fine) and (hover: hover) {
    .nav-item:hover::before {
      content: "";
      display: block;
      background-color: #fff;
      height: 60px;
      width: 4px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      position: absolute;
    }
  }
}
