/*------------------------------------*\
    $GRIDS
\*------------------------------------*/
/**
 * Fluid and nestable grid system, e.g.:
 *
   <div class="grid">

       <div class="grid__cell one-third">
           <p>One third grid</p>
       </div>

       <div class="grid__cell two-thirds">
           <p>Two thirds grid</p>
       </div>

       <div class="grid__cell one-half">
           <p>One half grid</p>
       </div>

       <div class="grid__cell one-quarter">
           <p>One quarter grid</p>
       </div>

       <div class="grid__cell one-quarter">
           <p>One quarter grid</p>
       </div>

   </div>
 *
 */

.grid {
    display: block;
    font-size: 0;
    margin: 0;
    padding: 0;
    text-align: left;
}

.grid__cell {
    box-sizing: border-box;
    display: inline-block;
    font-size: $default-font-size +px;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: top;

    &:empty:not(.grid__cell--spacer) {
        display: none;
        visibility: hidden;
    }
}

@mixin grid-setup($namespace: "") {

    .#{$namespace}grid--align-center {
        text-align: center;
    }

    .#{$namespace}grid--align-right {
        text-align: right;
    }

    .#{$namespace}grid--align-middle > .grid__cell {
        vertical-align: middle;
    }

    .#{$namespace}grid--align-bottom > .grid__cell {
        vertical-align: bottom;
    }

    .#{$namespace}grid--with-gutter {
        margin: 0 (-.5 * $default-spacing-unit +px);

        > .grid__cell {
            padding: 0 (.5 * $default-spacing-unit +px);
          }
    }
}

@include grid-setup();

@if $responsive == true {

    @include media-query(palm) {
        @include grid-setup("palm-");
    }

    @include media-query(lap) {
        @include grid-setup("lap-");
    }

    @include media-query(lap-and-up) {
        @include grid-setup("lap-and-up-");
    }

    @include media-query(portable) {
        @include grid-setup("portable-");
    }

    @include media-query(desk) {
        @include grid-setup("desk-");
    }
}