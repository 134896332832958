.react-tabs__tab-list {
  border: none;
}

.react-tabs__tab {
  font-family: $univers-cond;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 16px;
  color: $black;
  border: none;
  padding: 24px 7px 16px 7px;
  margin: 0px 18px;
}

.react-tabs__tab--selected {
  background: none;
  position: relative;
}

.react-tabs__tab--selected:after {
  position: absolute;
  background: #293342;
  content: "";
  bottom: -4px;
  left: 0px;
  width: 100%;
  right: 0px;
  height: 4px;
  border-radius: 2px;
}

.react-tabs__tab:focus {
  box-shadow: none;
  border-color: #293342;
  outline: none;
}

.react-tabs__tab:focus:after {
  background-color: transparent;
}
