.user-login {
  li {
    font-size: 18px;
    line-height: 22px;
    color: $dim-grey;
  }

  .radio-region input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkpoint {
    position: absolute;
    top: 9px;
    left: 0;
    height: 13px;
    width: 13px;
    border: 1px solid $med-grey-1;
    border-radius: 50%;
  }

  .checkpoint:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio-region input:checked ~ .checkpoint:after {
    display: block;
  }

  .radio-region .checkpoint:after {
    top: 2px;
    left: 2px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #2196f3;
  }

  .radio-region input:checked ~ .checkpoint {
    border: 1px solid #2196f3;
  }
}
