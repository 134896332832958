/*------------------------------------*\
    $LAYOUT
\*------------------------------------*/
/**
 * A series of helper classes to use that generally
 * affect the layout.
 */

/**
 * Floats
 */
@mixin float-setup($namespace: "") {
    .#{$namespace}fr {
        float: right;
    }

    .#{$namespace}fl {
        float: left;
    }

    .#{$namespace}float-none {
        float: none;
        clear: both;
    }

    .#{$namespace}clearfix::after {
        content: "";
        display: table;
        clear: both;
    }
}

@include float-setup();


@if $responsive == true {

    @include media-query(palm) {
        @include float-setup("palm-");
    }

    @include media-query(lap) {
        @include float-setup("lap-");
    }

    @include media-query(lap-and-up) {
        @include float-setup("lap-and-up-");
    }

    @include media-query(portable) {
        @include float-setup("portable-");
    }

    @include media-query(desk) {
        @include float-setup("desk-");
    }
}
/**
 * Contain floats
 *
 * Make an element expand to contain floated children.
 * Uses pseudo-elements (micro clearfix).
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of the
 *    element.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf {
    zoom: 1;

    &:before,
    &:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }

    &:after {
        clear: both;
    }
}

@mixin align-setup($namespace: "") {
    .#{$namespace}align-top      { vertical-align: top; }
    .#{$namespace}align-middle   { vertical-align: middle; }
    .#{$namespace}align-baseline { vertical-align: baseline; }
    .#{$namespace}align-bottom   { vertical-align: bottom; }
    .#{$namespace}align-initial  { vertical-align: initial; }
}

@include align-setup();

@if $responsive == true {
    @include media-query(palm) {
        @include align-setup("palm-");
    }

    @include media-query(lap) {
        @include align-setup("lap-");
    }

    @include media-query(lap-and-up) {
        @include align-setup("lap-and-up-");
    }

    @include media-query(portable) {
        @include align-setup("portable-");
    }

    @include media-query(desk) {
        @include align-setup("desk-");
    }
}

.overflow-x--hide   { overflow-x: hidden !important; }
.overflow-y--hide   { overflow-y: hidden !important; }
.overflow--hide     { overflow: hidden !important; }

.overflow-x--show   { overflow-x: visible !important; }
.overflow-y--show   { overflow-y: visible !important; }
.overflow--show     { overflow: visible !important; }

.overflow-x--scroll { overflow-x: scroll !important; }
.overflow-y--scroll { overflow-y: scroll !important; }
.overflow--scroll   { overflow: scroll !important; }

.overflow-x--auto   { overflow-x: auto !important; }
.overflow-y--auto   { overflow-y: auto !important; }
.overflow--auto     { overflow: auto !important; }
