/*------------------------------------*\
$TABLES
\*------------------------------------*/
/**
* We have a lot at our disposal for making very complex table constructs, e.g.:
*
    <table>
        <colgroup>
        <col class=t10>
        <col class=t10>
        <col class=t10>
        <col>
    </colgroup>
    <thead>
        <tr>
            <th colspan=3>Foo</th>
            <th>Bar</th>
        </tr>
        <tr>
            <th><button class="sortable">Lorem</button></th>
            <th><button class="sortable--ascending">Ipsum</button></th>
            <th class=numerical>Dolor</th>
            <th>Sit</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th rowspan=3>Sit</th>
            <td>Dolor</td>
            <td class=numerical>03.788</td>
            <td>Lorem</td>
        </tr>
        <tr>
            <td>Dolor</td>
            <td class=numerical>32.210</td>
            <td>Lorem</td>
        </tr>
        <tr>
            <td>Dolor</td>
            <td class=numerical>47.797</td>
            <td>Lorem</td>
        </tr>
        <tr>
            <th rowspan=2>Sit</th>
            <td>Dolor</td>
            <td class=numerical>09.640</td>
            <td>Lorem</td>
        </tr>
        <tr>
            <td>Dolor</td>
            <td class=numerical>12.117</td>
            <td>Lorem</td>
        </tr>
    </tbody>
    </table>
*
*/
/*
* Baseline styles
*/

table {
  background: transparent;
  max-width: 100%;
  width: 100%;

  /*
    * Cells
    */
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top: 1px solid $default-ui-color;
        border-bottom: 1px solid $default-ui-color;
        line-height: $line-height-ratio;
        padding: $half-spacing-unit + px;
        text-align: left;
        vertical-align: top;

        &:first-child {
          padding-left: $default-spacing-unit + px;
        }

        &:last-child {
          padding-right: $default-spacing-unit + px;
        }
      }
    }
  }

  /*
    * Bottom align for column headings
    */
  > thead > tr > th {
    border-bottom: 1px solid $default-ui-color;
    vertical-align: bottom;
  }

  /*
    * Remove top border from thead by default
    */
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child:not(.with-top-border) {
      > th,
      > td {
        border-top: 0;
      }
    }
  }

  /*
    * Account for multiple tbody instances
    */
  > tbody + tbody {
    border-top: 2px solid $default-ui-color;
  }
}

/*
* Hover effect
*/
.table--hover {
  > tbody > tr:hover {
    background-color: $shade-4-color;
    cursor: pointer;
  }
}

/*
* Table cell sizing
* 
* Reset default table behavior
*/
table col[class*="col-"] {
  display: table-column;
  float: none;
  position: static; /* Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623) */
}

td,
th {
  &[class*="col-"] {
    display: table-cell;
    float: none;
    position: static; /* Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623) */
  }
}

.table-wrapper {
  display: inline-block;
  margin-bottom: $default-spacing-unit + px;
  overflow: auto;
  vertical-align: top;
  width: 100%;
}

.table--scrollable {
  overflow: auto;
  overflow-x: hidden;
  max-height: 325px;

  tbody > tr:first-child > td {
    border: none;
  }
}

.t-min-width-54 {
  min-width: 54px;
}

/**
* In the HTML above we see several `col` elements with classes whose numbers
* represent a percentage width for that column. We leave one column free of a
* class so that column can soak up the effects of any accidental breakage in
* the table.
*/
.t1 {
  width: 1%;
}
.t5 {
  width: 5%;
}
.t10 {
  width: 10%;
}
.t12 {
  width: 12.5%;
} /* 1/8 */
.t15 {
  width: 15%;
}
.t20 {
  width: 20%;
}
.t25 {
  width: 25%;
} /* 1/4 */
.t30 {
  width: 30%;
}
.t33 {
  width: 33.333%;
} /* 1/3 */
.t35 {
  width: 35%;
}
.t37 {
  width: 37.5%;
} /* 3/8 */
.t40 {
  width: 40%;
}
.t45 {
  width: 45%;
}
.t50 {
  width: 50%;
} /* 1/2 */
.t55 {
  width: 55%;
}
.t60 {
  width: 60%;
}
.t62 {
  width: 62.5%;
} /* 5/8 */
.t65 {
  width: 65%;
}
.t66 {
  width: 66.666%;
} /* 2/3 */
.t70 {
  width: 70%;
}
.t75 {
  width: 75%;
} /* 3/4*/
.t80 {
  width: 80%;
}
.t85 {
  width: 85%;
}
.t87 {
  width: 87.5%;
} /* 7/8 */
.t90 {
  width: 90%;
}
.t95 {
  width: 95%;
}

.table-new {
  th {
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  td {
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 20px;
    padding-top: 20px;
    vertical-align: middle;
  }
}
