/*------------------------------------*\
    $BOX
\*------------------------------------*/
/**
 * Boxes used to add borders and background
 * colors to divs in a consistent way. Usually
 * combined with other block level elements but
 * not required.
 *
   <div class="block-list box--white">
      <li>Foo</li>
      <li>Bar</li>
      <li>Baz</li>
   </ul>
 *
 * or
 *
    <div class="island box--white">
        <h1>Island content goes here.</h1>
   </ul>
 *
 */
[class*='box--'] {
    border-color: inherit;
}

[class*='box--border-'] {
    border-style: solid;
    border-width: 0;
}

@mixin box-setup($namespace: "") {
    /**
     * Box classes that define background and border colors.
     */

    .#{$namespace}box--black {
        background: #000;
        border-color: #000;
        color: #fff;
    }

    .#{$namespace}box--brand-green {
        background: $brand-color;
        border-color: shade($brand-color, 10%);
        color: #000;
    }

    .#{$namespace}box--brand-blue {
        background: $brand-secondary-color;
        border-color: shade($brand-secondary-color, 10%);
        color: #000;
    }

    .#{$namespace}box--gray {
        background: $shade-2-color;
        border-color: $shade-1-color;
        color: #fff;
    }

    .#{$namespace}box--gray-light {
        background: $shade-4-color;
        border-color: $shade-3-color;
        color: $default-text-color;
    }

    .#{$namespace}box--gray-light-background {
        background-color: #f6f6f6;
    }

    .#{$namespace}box--gray-extra-light {
        background: tint($shade-4-color, 75%);
        border-color: $shade-4-color;
        color: $default-text-color;
    }

    .#{$namespace}box--white {
        background: #fff;
        border-color: $shade-3-color;
        color: $default-text-color;

        &.#{$namespace}box--translucent {
            background-color: rgba(255, 255, 255, 0.8);
        }

        &.#{$namespace}box--with-arrow {
            position: relative;

            &:after,
            &:before {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &:after {
                border-top-color: #fff;
                border-width: 8px;
                margin-left: -8px;
            }

            &.#{$namespace}box--border-all:before {
                border-top-color: inherit;
                border-width: 9px;
                margin-left: -9px;
            }
        }
    }

    .#{$namespace}box--green {
        background: $green;
        border-color: shade($green, 20%);
        color: #fff;
    }

    .#{$namespace}box--green-light {
        background: $green-light;
        border-color: tint($green, 65%);
        color: $default-text-color;
    }

    .#{$namespace}box--blue {
        background: $blue;
        border-color: shade($blue, 20%);
        color: #fff;
    }

    .#{$namespace}box--blue-light {
        background: $blue-light;
        border-color: tint($blue, 65%);
        color: $default-text-color;
    }

    .#{$namespace}box--yellow {
        background: $yellow;
        border-color: shade($yellow, 20%);
        color: $default-text-color;
    }

    .#{$namespace}box--yellow-medium {
        background: $yellow-medium;
        border-color: tint($yellow, 65%);
        color: $default-text-color;
    }

    .#{$namespace}box--yellow-light {
        background: $yellow-light;
        border-color: tint($yellow, 65%);
        color: $default-text-color;
    }

    .#{$namespace}box--orange {
        background: $orange;
        border-color: shade($orange, 20%);
        color: #fff;
    }

    .#{$namespace}box--orange-medium {
        background: $orange-medium;
        border-color: tint($orange, 65%);
        color: $default-text-color;
    }

    .#{$namespace}box--orange-light {
        background: $orange-light;
        border-color: tint($orange, 65%);
        color: $default-text-color;
    }

    .#{$namespace}box--red {
        background: $red;
        border-color: shade($red, 20%);
        color: #fff;
    }

    .#{$namespace}box--red-medium {
        background: $red-medium;
        border-color: tint($red, 65%);
        color: $default-text-color;
    }

    .#{$namespace}box--red-light {
        background: $red-light;
        border-color: tint($red, 65%);
        color: $default-text-color;
    }

    .#{$namespace}box--border-all           { border-width:        1px; }
    .#{$namespace}box--border-left          { border-left-width:   1px; }
    .#{$namespace}box--border-right         { border-right-width:  1px; }
    .#{$namespace}box--border-top           { border-top-width:    1px; }
    .#{$namespace}box--border-bottom        { border-bottom-width: 1px; }
    .#{$namespace}box--border-bottom-none   { border-bottom-width: 0 !important; }
    .#{$namespace}box--border-ends          { border-bottom-width: 1px; border-top-width:   1px; }
    .#{$namespace}box--border-sides         { border-left-width:   1px; border-right-width: 1px; }
    .#{$namespace}box--border-none          { border-width:        0 !important; }
    .#{$namespace}box--border-dashed        { border-style: dashed; }
    .#{$namespace}box--rounded-all          { border-radius: $brand-round +px; }
    .#{$namespace}box--rounded-border-grey  { border-radius: $brand-round +px !important;
                                              border-width: 1px !important;
                                              border-style: solid !important;
                                              border-color: #c9cacc !important;
                                            }
    .#{$namespace}box--rounded-top          { border-radius: $brand-round +px $brand-round +px 0 0; }
    .#{$namespace}box--rounded-right        { border-radius: 0 $brand-round +px $brand-round +px 0; }
    .#{$namespace}box--rounded-bottom       { border-radius: 0 0 $brand-round +px $brand-round +px; }
    .#{$namespace}box--rounded-left         { border-radius: $brand-round +px 0 0 $brand-round +px; }
}

@include box-setup();

@if $responsive == true {
    @include media-query(palm) {
        @include box-setup("palm-");
    }

    @include media-query(lap) {
        @include box-setup("lap-");
    }

    @include media-query(lap-and-up) {
        @include box-setup("lap-and-up-");
    }

    @include media-query(portable) {
        @include box-setup("portable-");
    }

    @include media-query(desk) {
        @include box-setup("desk-");
    }
}

/**
 * Equipment type colors
 */

.box--equipment-container {
    background: $equipment-container-color;
    border-color: shade($equipment-container-color, 20%);
    color: $default-text-color;
}

.box--equipment-curtain {
    background: $equipment-curtain-color;
    border-color: shade($equipment-curtain-color, 20%);
    color: $default-text-color;
}

.box--equipment-euroliner {
    background: $equipment-euroliner-color;
    border-color: shade($equipment-euroliner-color, 20%);
    color: $default-text-color;
}

.box--equipment-flatbed {
    background: $equipment-flatbed-color;
    border-color: shade($equipment-flatbed-color, 20%);
    color: $default-text-color;
}

.box--equipment-low-loader {
    background: $equipment-low-loader-color;
    border-color: shade($equipment-low-loader-color, 20%);
    color: $default-text-color;
}

.box--equipment-reefer {
    background: $equipment-reefer-color;
    border-color: shade($equipment-reefer-color, 20%);
    color: $default-text-color;
}

.box--equipment-multiple {
    background: $equipment-multiple-color;
    border-color: shade($equipment-multiple-color, 20%);
    color: $default-text-color;
}

.box--equipment-other {
    background: $equipment-other-color;
    border-color: shade($equipment-other-color, 20%);
    color: $default-text-color;
}

.box--equipment-power-only {
    background: $equipment-power-only-color;
    border-color: shade($equipment-power-only-color, 20%);
    color: $default-text-color;
}

.box--equipment-van {
    background: $equipment-van-color;
    border-color: shade($equipment-van-color, 20%);
    color: $default-text-color;
}