.analytics {
  .analytics-filters {
    height: 50px;
    border-bottom: 1px solid $light-grey-3;

    .datepicker-icon {
      display: none;
    }

    .text-button {
      &:disabled {
        color: $shade-3-color !important;
      }
    }

    .auto-complete__menu {
      z-index: 2;
    }
  }

  .analytics-summary {
    height: calc(100% - 50px);

    .label {
      font-weight: 700;
      padding-top: 21px;
    }

    .value {
      font-size: 28px;
      font-family: $univers-cond;
    }
  }

  .charts {
    position: relative;
    height: calc(100% - 50px);
    width: calc(50% - 4px);

    .chart-heading {
      font-size: 13px;
      font-weight: 700;
      color: $grayish-blue;
      margin-left: 26px;
      margin-top: 21px;
      text-transform: unset;
    }

    .chart-type {
      right: 20px;
      top: 12px;
    }

    .recharts-cartesian-grid-vertical line:nth-last-child(2),
    .recharts-cartesian-grid-vertical line:last-child {
      opacity: 0;
    }

    .recharts-default-legend {
      font-weight: 700;
      padding-bottom: 20px !important;

      .recharts-legend-item {
        padding-right: 16px;
      }
    }

    .recharts-label,
    .yaxis-label,
    .x-axis-label {
      font-weight: 700;
      font-size: 10px;
      letter-spacing: 0.12px;
      font-family: $font-stack !important;
    }

    .recharts-text:not(.yaxis-label) {
      font-size: 10px;
      font-family: $univers-cond;
    }

    .recharts-legend-item-text {
      font-size: 11px;
      color: $grayish-blue !important;
    }

    .tooltip {
      p {
        margin-bottom: 4px;
      }
    }

    .frequency-graph {
      .recharts-cartesian-grid-vertical line:nth-last-child(2) {
        opacity: 1;
      }

      .recharts-cartesian-grid-vertical line:first-child {
        opacity: 0;
      }

      .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick:last-child {
        display: none;
      }
    }
  }

  .breakdown-graph {
    .recharts-default-legend {
      margin-top: 12px !important;
    }
  }

  .rank-tick {
    font-size: 10px;
    font-family: $font-stack !important;
  }
  .no-matches-panel,
  .box {
    height: 316px;
  }

  .slick-list:focus-within {
    height: 100vh;
    z-index: 2;
  }

  .slick-prev,
  .slick-next {
    z-index: 9;
    top: 22px;
    border: 1px solid $shade-1-color;
    height: 24px;
    width: 24px;
    background-color: $white;
  }

  .slick-prev {
    left: -6px;

    svg {
      bottom: 14px;
      right: 0.5px;
    }
  }

  .slick-next {
    right: -6px;

    svg {
      bottom: 14px;
      left: 0.5px;
    }
  }

  .slick-disabled {
    display: none !important;
  }

  .apply-button {
    right: 124px;
    top: 4px;
  }

  .screen-mode {
    top: 4px;
    right: 0px;
  }

  .graph-section {
    position: relative;
    height: calc(100% - 50px);
    width: calc(70% - 4px);

    .chart-heading {
      font-size: 13px;
      font-weight: 700;
      color: $grayish-blue;
      margin-left: 26px;
      margin-top: 21px;
      text-transform: unset;
    }

    .chart-type {
      right: 20px;
      top: 12px;
    }

    .recharts-cartesian-grid-vertical line:last-child {
      opacity: 0;
    }

    .recharts-default-legend {
      font-weight: 700;
      text-align: right !important;
      position: absolute !important;
      bottom: 222px !important;
      right: 20px !important;

      .recharts-legend-item {
        padding-right: 16px;
      }
    }

    .recharts-label,
    .yaxis-label,
    .x-axis-label {
      font-weight: 700;
      font-size: 10px;
      letter-spacing: 0.12px;
      font-family: $font-stack !important;
    }

    .recharts-text:not(.yaxis-label) {
      font-size: 10px;
      font-family: $univers-cond;
    }

    .recharts-legend-item-text {
      font-size: 11px;
      color: $grayish-blue !important;
    }

    .tooltip {
      p {
        margin-bottom: 4px;
      }
    }

    .recharts-tooltip-wrapper {
      visibility: visible !important;
    }

    .frequency-graph {
      .recharts-cartesian-grid-vertical line:nth-last-child(2) {
        opacity: 1;
      }

      .recharts-cartesian-grid-vertical line:first-child {
        opacity: 0;
      }

      .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick:last-child {
        display: none;
      }
    }
  }

  .rank-breakdown {
    .label {
      font-size: 12px;
      font-weight: 700;
      color: $dark-grey;
      text-transform: unset;
    }

    .heading {
      margin-top: 21px;
    }

    .tiny-label {
      font-size: 10px;
      font-weight: 400;
      text-transform: unset;
      font-style: italic;
      margin-bottom: 6px;
    }

    .key {
      color: $dark-grey;
      padding-left: 22px;
    }

    .value {
      font-family: $univers-light;
      color: $dim-grey;
      padding-right: 22px;
    }

    .info {
      width: 113px !important;
    }
  }
}

.radial-bar {
  height: 42px;

  .react-tabs__tab-list {
    margin: 0px;
  }

  .react-tabs__tab {
    font-family: "Helvetica";
    background-color: $site-background-color;
    border-radius: 0px;
    width: auto;
    font-size: 12px;
    line-height: 14px;
    padding: 12px 16px;
    margin-right: 0px;
  }

  .react-tabs__tab--selected {
    font-weight: 700;
  }

  .react-tabs__tab--selected::after {
    height: 3px;
    bottom: -1px;
  }
}

.analytics-table-width {
  width: 100%;
}

.analytics-table-width:hover .scroll::-webkit-scrollbar {
  display: block;
  margin-top: 48px;
}

.analytics-grid-section {
  .left-grid-section {
    width: 60% !important;
  }

  .right-grid-section {
    width: calc(40% - 4px);
  }

  .simple-cell,
  .header-cell {
    &:not(.selected-cell) {
      border-right: 1px white !important;
    }
  }

  tr:nth-child(even) {
    background-color: transparent !important;
  }

  .header-cell {
    .header-div {
      border-bottom-color: $silver !important;
    }
  }

  .shipper-rates {
    .grid-heading {
      font-size: 16px;
      font-weight: 500;
      font-family: $univers-cond;
      line-height: 17px;
      color: $black;
    }

    .origin-destination {
      font-family: $univers-cond;
      font-size: 16px;
      font-weight: 500;
      color: $black;
    }

    .radius {
      font-size: 10px;
      line-height: 12px;
      color: $dim-grey;
      font-family: $univers-light;
    }
  }
}

.drawer {
  .header {
    border-bottom: 1px solid $shade-4-color;
    font-size: 18px;
    font-weight: 700;
    font-family: $univers-light;
    color: $dark-brown;
  }

  .grey-color {
    color: $disabled-tab-color;
  }

  .default-font-family {
    font-family: $font-stack;
  }

  .disclaimer {
    color: $grayish-blue-2 !important;
  }

  .no-datRateView {
    font-size: 18px;
    line-height: 24px;
  }
}
