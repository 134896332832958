/*------------------------------------*\
    $LINKS
\*------------------------------------*/
/**
 * Say no to negative hovers, as per: csswizardry.com/2011/05/on-negative-hovers
 *
 * Sometimes, particularly on larger projects, it is useful to scope link
 * styling only to anchors which have `href` attributes.
 */

a,
.text-link {
    /**
     * Set colors etc in your theme stylesheet.
     */
    color: $link-color;
    cursor: pointer;
    text-decoration: underline;

    /**
     * Remove Chrome’s heavy-handed glow.
     */
    &:focus {
        outline: 2px solid rgba($brand-color,.35);
        outline-offset: 0;
    }

    &[disabled],
    &.disabled {
        opacity: .5;
        cursor: not-allowed;
        pointer-events: none;
    }

    /**
     * Link styles for new UI/UX design system
     * https://projects.invisionapp.com/d/main/#/console/17020525/352851055/preview
     */
    &--blue {
        color: $blue;
        text-decoration: none;
        
        &:hover,
        &:focus {
            text-decoration: underline;
        }
        
        &:active {
            text-decoration: none;
        }
        
        &[disabled], 
        &.disabled {
            opacity: 1;
            color: #adafb2;
        }
    }
}

/**
 * ‘Current’ states, e.g.:
 * 
   <ul class=nav>
       <li><a href=#>Home</a></li>
       <li><a href=#>About</a></li>
       <li class=current><a href=#>Portfolio</a></li>
       <li><a href=#>Contact</a></li>
   </ul>
 * 
 */
.current a:not(.text-link--blue),
.current .text-link:not(.text-link--blue) {
    cursor: text;
    text-decoration: underline;
}