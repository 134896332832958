.filter-menu {
  border-radius: 4px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  max-height: 250px;
  overflow: auto;
  z-index: 9998;
}

#filter-menu-content ul {
  li {
    padding-bottom: 10px;
  }

  li:first-child {
    margin-bottom: 10px;
    padding-bottom: 8px;
  }

  li:last-child {
    padding-bottom: 0;
  }
}

#filter-menu-content #pricing-grid-filter-menu {
  max-height: 135px;
  overflow: auto;
  padding-right: 8px;
  padding-top: 4px;
}

#filter-menu-content button:hover {
  background-color: transparent;
}

#range-filter .outlined-input {
  display: inline-block;
  height: 32px !important;
  width: 70px;
}

#range-filter button:hover {
  background-color: transparent;
}
