﻿/*------------------------------------*\
    $BULLET-LIST
\*------------------------------------*/
.bullet-list {
    > li {
        text-indent: 1.5em;

        &:before {
            display: inline-block;
            content: '';
            -webkit-border-radius: 0.125rem;
            border-radius: 0.125rem;
            height: 0.25rem;
            width: 0.25rem;
            margin-right: 0.5rem;
            margin-bottom: 0.25rem;
            background-color: #000000;
        }
    }

    .bullet-list ul {
        list-style: none;
        margin-left: 10px;
    }
}
