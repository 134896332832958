/*------------------------------------*\
    $RADIO BUTTONS
\*------------------------------------*/
/**
 * Radio button styles for new UI/UX design system
 * https://projects.invisionapp.com/d/main/?#/console/17020525/392693164/preview
 *
 * SCSS based on Materialize framework
 * https://github.com/Dogfalo/materialize/blob/master/sass/components/forms/_radio-buttons.scss
 *
 * <label>
 *     <input type="radio" name="group" value="value" />
 *     <span>Label</span>
 * </label>
 */

// Remove default Radio Buttons
.radio:not(:checked),
.radio:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.radio:not(:checked) + span,
.radio:checked + span {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    line-height: 23px;
    font-size: 1rem;
    transition: .28s ease;
    user-select: none;
}

.radio + span:before,
.radio + span:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px 4px 4px 0;
    width: 13px;
    height: 13px;
    z-index: 0;
    transition: .28s ease;
}

/* Unchecked styles */
.radio:not(:checked) + span:before,
.radio:not(:checked) + span:after,
.radio:checked + span:before,
.radio:checked + span:after {
    border-radius: 50%;
}

.radio:not(:checked) + span:before,
.radio:not(:checked) + span:after {
    border: 1px solid $med-grey-1;
}

.radio:not(:checked) + span:after {
    transform: scale(0);
}

/* Checked styles */
.radio:checked + span:before {
    border: 1px solid transparent;
}

.radio:checked + span:after,
.radio:checked + span:before {
    border: 1px solid $blue;
}

.radio:checked + span:after {
    background-color: $blue;
}

.radio:checked + span:after {
    transform: scale(0.5);
}

/* Focused styles */
.radio.tabbed:focus + span:before {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Disabled style */
.radio:disabled:checked + span:before {
    border: 1px solid $light-grey-1;
}

.radio:disabled:checked + span:after {
    border: none;
    background-color: $light-grey-1;
}

/* Disabled style */
.radio:disabled:checked + span:before {
    background-color: transparent;
    border-color: $light-grey-1;
}

.radio:disabled:not(:checked) + span:before {
    background-color: $light-grey-1;
    border-color: $med-grey-1;
}

.radio:disabled + span {
    color: $light-grey-1;
}

.radio:disabled:not(:checked) + span:after {
    transform: scale(1)
}

/* Error style */
.radio:invalid + span,
.radio.error + span {
    color: $alert-red;
}

.radio:invalid + span:after,
.radio:invalid + span:before,
.radio.error + span:after,
.radio.error + span:before
{
    border: 1px solid $alert-red;
}

.radio:invalid:checked + span:after,
.radio.error:checked + span:after {
    background-color: $alert-red;
}