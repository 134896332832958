.pending-bids {
  .pending-bids-container {
    max-width: 1024px;
    min-width: 892px;
    margin: 0 auto;
  }

  .pending-bids-grid {
    align .row:last-child {
      border-radius: 0 0 4px 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      border-bottom: 0px;
    }

    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-radius: 0 0 4px 4px;
  }

  .header-cell {
    color: $dark-grey;
    font-family: $font-stack;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    user-select: none;
    padding: 12px 8px;

    &:hover {
      background: $site-background-color;
    }

    border-bottom: 0px;
  }
  .header-cell + .right {
    text-align: right;
    padding-left: 28px;
  }
  .header-cell + .left {
    text-align: left;
    padding-right: 28px;
  }

  .left .sort-icon {
    margin-left: 4px;
  }

  .right .sort-icon {
    margin-right: 4px;
  }

  .body-cell {
    font-family: $font-stack;
    font-size: 12px;
    line-height: 14px;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    .spinner {
      top: -5px;
      left: 2px;
    }
  }

  .header-row {
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid $shade-4-color;

    .header-cell:last-child {
      padding-right: 20px;
    }
    .header-cell.right.sorted-header {
      padding: 12px 8px 12px 0px;
    }
    .header-cell.left.sorted-header {
      padding: 12px 0px 12px 8px;
    }
    .header-cell:first-child {
      padding-left: 20px !important;
      padding-right: 28px;
    }
  }

  .row {
    height: 48px;
    background-color: #ffffff;
    border-bottom: 1px solid $shade-4-color;

    &:hover {
      background-color: $coconut-white;
    }

    .body-cell:first-child {
      padding-left: 20px;
    }

    .body-cell:last-child {
      padding-right: 20px;
    }
  }

  .sorted-header {
    background: $site-background-color;
  }

  .close-icon:hover {
    background-color: #ffd7cc;
  }

  .check-icon:hover {
    background-color: $req-equipment-color;
  }
  .no-pending-bids {
    height: 100vh;
    .title {
      font-size: 34px;
      line-height: 32px;
    }
    .message {
      font-size: 18px;
      line-height: 22px;
      color: $dim-grey;
      font-family: Helvetica Neue, Helvetica, Arial, sans- serif;
    }
  }

  .auto-complete {
    width: 200px;
    height: auto !important;
    top: 2px;
  }

  .auto-complete__option {
    text-align: left;
  }
}
