.notifications {
  .notification {
    width: 100%;
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 20px;
    margin-bottom: 12px;
    .unread {
      width: 8px;
      height: 8px;
      background: $rfq-green;
      border-radius: 50%;
      position: absolute;
      top: 32px;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .marking-read {
      position: absolute;
      top: 30px;
      left: 16px;
    }
    .icon {
      margin: 0px 16px 0px 0px;
    }
    figure {
      margin: 0px;
    }
    .content {
      margin-right: 24px;
      font-size: 14px;
      .user-name {
        font-weight: bold;
        padding-left: 3px;
        padding-right: 3px;
      }
      .bid-name {
        color: $blue;
        text-decoration: none;
        padding-left: 3px;
        padding-right: 3px;
        cursor: pointer;
      }
      .message {
        display: block;
        padding-top: 8px !important;
        width: 100%;
        padding-left: 3px;
      }
    }
    .time {
      right: 22px;
      top: 28px;
      display: inline-block;
      color: #575757;
      font-size: 12px;
      position: absolute;
    }
    .lane-upload-error-button {
      .icon {
        margin-top: 2px;
      }
      .lane-error-download {
        color: $blue;
        margin-left: 20px;
      }
    }
  }
  .text-button:disabled {
    color: $med-grey-1;
  }

  .flyout-notification {
    text-align: left;
    width: 100%;
    position: relative;
    background-color: #ffffff;
    border-bottom: 1px solid #e4e4e4;
    padding: 16px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    .unread {
      width: 6px;
      height: 6px;
      background: $rfq-green;
      border-radius: 50%;
      position: absolute;
      top: 32px;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .marking-read {
      position: absolute;
      top: 25px;
      left: 16px;
    }
    .t80 {
      display: inline-block;
    }
    .ml-16 {
      display: inline-block !important;
    }
    .icon {
      margin: 0px 12px 0px 0px;
    }
    figure {
      margin: 0px;
    }
    .content {
      font-size: 12px;
      display: inline-block;
      .user-name {
        font-weight: bold;
        padding-right: 3px;
        display: inline-block;
      }
      .bid-name {
        color: $blue;
        text-decoration: none;
        padding-left: 3px;
        padding-right: 3px;
        display: inline;
        cursor: pointer;
      }
      .message {
        display: block;
        padding-top: 4px !important;
        line-height: 16px;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .time {
      font-size: 10px;
      padding-top: 4px;
    }
    .lane-upload-error-button {
      margin-bottom: 6px;
      .lane-error-download {
        color: $blue;
        margin-left: 20px;
      }
    }
    ul {
      li::before {
        top: 4px;
      }
    }
  }
  .flyout-notification:last-child {
    border-bottom: none;
  }

  ol {
    counter-reset: my-sec-counter;
    li::before {
      counter-increment: my-sec-counter;
      content: counter(my-sec-counter) ". ";
    }
    ol {
      padding-left: 14px;
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 12px;
    }
    li::before {
      position: absolute;
      content: "";
      background: $black;
      left: 0px;
      width: 6px;
      height: 6px;
      top: 6px;
      border-radius: 100%;
    }
    ul {
      padding-left: 14px;
    }
  }
}

.flyout-window {
  width: 434px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  font-size: 12px;
  line-height: 14px;
  position: fixed;

  & > .tippy-arrow::before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }

  &[data-placement^="right"] > .tippy-arrow {
    left: 0;
    transform: translate3d(2px, 3px, 0px) !important;

    &::before {
      left: -17px;
      border-width: 24px 24px 24px 0;
      border-right-color: #ffffff;
      transform-origin: center right;
    }
  }
}
