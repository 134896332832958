/*------------------------------------*\
    $LINKS
\*------------------------------------*/
/**
 * Define svg's in SvgDefs.cshtml file. Add any sizing
 * customization below and use the following for implentation:
 *
   <svg class="svg-icon--export">
       <use xlink:href="#svg-icon--export"></use>
   </svg>
 *
 */

// TODO: Change class name prefix to just "icon" after icon font is replaced

[class^="svg-icon--"],
[class*=" svg-icon--"] {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    vertical-align: -15%;
    width: 1em;
    @include font-size(16, false);
    &.alpha {
        @include font-size($h1-size);
    }
    &.beta {
        @include font-size($h2-size);
    }
    &.gamma {
        @include font-size($h3-size);
    }
    &.delta {
        @include font-size($h4-size + 8); // 24px
    }
    &.epsilon {
        @include font-size($h5-size);
    }
    &.zeta {
        @include font-size($h6-size);
    }
    &.giga {
        @include font-size($giga-size);
    }
    &.mega {
        @include font-size($mega-size);
    }
    &.kilo {
    @include font-size($kilo-size);
    }

    &.milli {
        @include font-size($milli-size);
    }
}

.svg-icon--add,
.svg-icon--subtract {
    width: 0.9em;
}

.svg-icon--alcohol {
    width: 0.7998046875em;
}

.svg-icon--arrow-down {
    width: 0.6em;
}

.svg-icon--arrow-up {
    width: 0.6em;
}

.svg-icon--calendar {
    width: 0.900390625em;
}

.svg-icon--check {
    width: 0.6669921875em;
}

.svg-icon--checkbox-complete {
    width: 0.9462890625em;
}

.svg-icon--chevron-down {
    width: 0.580078125em;
}

.svg-icon--chevron-left {
    width: 0.33984375em;
}

.svg-icon--chevron-right {
    width: 0.33984375em;
}

.svg-icon--chevron-up {
    width: 0.580078125em;
}

.svg-icon--clear {
    width: 0.5em;
}

.svg-icon--clock {
    width: 1.0em;
    height: 1.0em;
}

.svg-icon--expire {
    width: 1.0em;
    height: 1.0em;
}

.svg-icon--credit {
    width: 0.55em;
}

.svg-icon--box {
    width: 1.0em;
    height: 1.0em;
}

.svg-icon--coyote-brandmark {
    width: 6.7138671875em;
}

.svg-icon--coyote-symbol {
    width: 1.6669921875em;
}

.svg-icon--details {
    width: 0.892em;
}

.svg-icon--drop {
    width: 0.5595703125em;
}

.svg-icon--edit {
    width: 0.8798828125em;
}

.svg-icon--flag {
    width: 0.900390625em;
}

.svg-icon--hazmat {
    width: 1.078125em;
}

.svg-icon--hide {
    width: 1.078125em;
}

.svg-icon--hidden {
    width: 1.078125em;
}

.svg-icon--new {
    width: 0.9384765625em;
}

.svg-column-sort {
    width: 14px;
    height: 14px;
    color: #343434;
    margin-top: 1.07px;
    position: absolute;
}

/**
 * Animation for loading/busy indicator.
 */
@keyframes spin {
    0%   { transform: rotate(0deg) }
    100% { transform: rotate(359deg) }
}

@keyframes spin-and-center {
    0%   { transform: rotate(0deg) translateY(-50%) }
    100% { transform: rotate(359deg) translateY(-50%) }
}

.svg-icon--loading {
    animation: 1.25s linear infinite spin;
    width: 0.82em;
    opacity: .5;

    /**
     * Since the spin animation uses transform, we need to add
     * additional transform styling to add-in items in order for
     * the object to be centered.
     */
    &.add-in__right-item,
    &.add-in__left-item {
        animation: 1.25s linear infinite spin-and-center;
        transform-origin: 50% 0;
    }
}

.svg-icon--location {
    width: 0.9462890625em;
}

.svg-icon--mail {
    width: 0.900390625em;
}

.svg-icon--menu {
    width: 0.7001953125em;
}

.svg-icon--move-in {
    width: 0.900390625em;
}

.svg-icon--move-out {
    width: 0.900390625em;
}

.svg-icon--notification {
    width: 0.904296875em;
}

.svg-icon--note {
    width: 0.7001953125em;
}

#svg-icon--map-pin {
    .path1 {
        fill: #000;
    }
    .path2 {
        fill: #fff;
    }
    .path3 {
        fill: #29a016;
    }
    .path4 {
        fill: $brand-color;
    }
}

.svg-icon--price-tag {
    width: 0.9736328125em;
}

.svg-icon--remove {
    width: 0.599609375em;
}

.svg-icon--reply {
    width: 0.900390625em;
}

.svg-icon--save {
    width: 0.7998046875em;
}

.svg-icon--search {
    width: 0.7900390625em;
}

.svg-icon--select-arrows {
    width: 0.5em;
}

.svg-icon--settings {
    width: 0.83984375em;
}

.svg-icon--sort {
    width: 0.7001953125em;
}

.svg-icon--star-outlined {
    width: 0.8798828125em;
}

.svg-icon--star {
    width: 0.8798828125em;
}

.svg-icon--thermometer {
    width: 0.5400390625em;
}

.svg-icon--thumbs-up {
    width: 0.8916015625em;
}

.svg-icon--triangle-down {
    width: 0.5em;
}

.svg-icon--triangle-up {
    width: 0.5em;
}

.svg-icon--user {
    width: 0.900390625em;
}

.svg-icon--warning {
    width: 0.9599609375em;
}

// Svg buget graph css
.svg-icon--budget-graph {
    width: 75%;
    height: 350px;

    @include media-query(lap) {
        width: 100%;
    }
}

.svg-icon--small-budget-graph {
    width: 100%;
    height: 250px;
}

.svg-icon--fuel {
    width: 0.900390625em;
}

.svg-icon--chevron-arrow-up {
    width: 0.900390625em;
}

.svg-icon--chevron-arrow-down {
    width: 0.900390625em;
}

.svg-icon--load-search {
    width: 0.900390625em;
}

.svg-icon--close {
    width: 0.900390625em;
}

.svg-icon--error {
    width: 0.900390625em;
}

.svg-icon--green-check {
    width: 0.900390625em;
}

.svg-icon--tips {
    height:1.250em;
    width: 0.900390625em;
}

.svg-icon--delete {
    width: 0.900390625em;
}

.svg-icon--pickup-pin {
    width: 0.900390625em;
}

.svg-icon--helper {
    width: 0.9em;
}

.svg-icon--sort-arrow {
    width: 0.8em;
}

.st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #EFEFEF;
}

.st1 {
    fill: #565656;
}

.st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #41FF23;
    stroke-width: 2;
    stroke-miterlimit: 100;
}

.st3 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #41FF23;
}

.st4 {
    fill: #FFFFFF;
}

.st5 {
    fill: #14DCFA;
}

.st6 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #EDF2F6;
}

.st7 {
    font-family: 'HelveticaNeue-Bold';
}

.st8 {
    font-size: 9.0826px;
}

.st9 {
    fill: #343434;
}

.st10 {
    fill: #41FF23;
}

.st11 {
    opacity: 0.8;
}

.st12 {
    fill: #A0A0A0;
}

.st13 {
    fill: #333333;
}

.st14 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #343434;
}

.user-avatar {
    border: 2px solid black;
    background-color: $shade-3-color;
    border-radius: 50%;
}

.user-avatar--small {
    height: 38px;
    width: 38px;
}

.user--status {
    position: relative;
    right: 11px;
    top: 33px;
}
.user--status-small {
    right: 10px;
    top: 24px;
}
.filter-icon--filled {
    fill: #41ff23;
    stroke: #41ff23;
    stroke-miterlimit: 10;
}

.filter-icon--empty {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
    stroke-width: 3px;
}

.even-odd--fill {
    fill-rule: evenodd;
}
