/*------------------------------------*\
    $TEXT
\*------------------------------------*/
/**
 * Horizontal text alignment
 */
@mixin text-setup($namespace: "") {
  .#{$namespace}text-center {
    text-align: center;
  }

  .#{$namespace}text-left {
    text-align: left;
  }

  .#{$namespace}text-right {
    text-align: right;
  }

  .#{$namespace}truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@include text-setup();

@if $responsive == true {
  @include media-query(palm) {
    @include text-setup("palm-");
  }

  @include media-query(lap) {
    @include text-setup("lap-");
  }

  @include media-query(lap-and-up) {
    @include text-setup("lap-and-up-");
  }

  @include media-query(portable) {
    @include text-setup("portable-");
  }

  @include media-query(desk) {
    @include text-setup("desk-");
  }
}
/**
 * Font weights
 */
.weight--light {
  font-weight: 300 !important;
}

.weight--normal {
  font-weight: 400 !important;
}

.weight--semibold {
  font-weight: 700 !important;
}

.weight--bold {
  font-weight: 900 !important;
}

.truncate--with-down-arrow:before {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon--triangle-down" width="12" height="16" viewBox="0 0 512 1024"><path fill="black" d="M0 281.6h512l-256 460.8-256-460.8z"/></svg>');
  float: right;
}

/**
 * Use <i>s instead of <span>s.
 */
i {
  font-style: normal;
}

/**
 * Apply capital case to an element (usually a `strong`).
 */
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.strikethrough {
  @extend .muted;
  text-decoration: line-through;
}

/**
 * Wrap long lines of unbroken text
*/
.word-break {
  word-break: break-word;
}

/**
 * Helper text colors
*/
.text-blue {
  color: $blue;
}

.text-red {
  color: $red;
}

.text-base {
  color: $default-text-color;
}

.text-green {
  color: $brand-color;
}

.text-light-grey {
  color: $med-grey-1;
}

.text-dim-grey {
  color: $dim-grey;
}

.text-rfq-red {
  color: $rfq-red;
}

.underline {
  text-decoration: underline;
}

.align-middle {
  vertical-align: middle !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-super {
  vertical-align: super !important;
}
