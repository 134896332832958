/*------------------------------------*\
    $MATRIX
\*------------------------------------*/
/**
 * Create a grid of items out of a single list, e.g.:
 * 
   <ul class="matrix  three-cols">
       <li class=all-cols>Lorem</li>
       <li>Ipsum <a href=#>dolor</a></li>
       <li><a href=# class=matrix__link>Sit</a></li>
       <li>Amet</li>
       <li class=all-cols>Consectetuer</li>
   </ul>
 * 
 * Extend this object in your theme stylesheet.
 * 
 * Demo: jsfiddle.net/inuitcss/Y2zrU
 * 
 */
.matrix {
    @extend .cf;
    border: 0 solid $default-ui-color;
    border-left-width: 1px;
    border-top-width: 1px;
    list-style: none;
    margin-left: 0;

    > li {
        border: 0 solid $default-ui-color;
        border-bottom-width: 1px;
        border-right-width: 1px;
        float: left;
        padding: $half-spacing-unit +px;
    }
}

.matrix__link {
    display: block;
    margin: -$half-spacing-unit +px;
    padding: $half-spacing-unit +px;
}


/**
 * The `.multi-list` object is a lot like the `.matrix` object only without the
 * blocky borders and padding.
 * 
   <ul class="multi-list  four-cols">
       <li>Lorem</li>
       <li>Ipsum</li>
       <li>Dolor</li>
       <li>Sit</li>
   </ul>
 * 
 * Demo: jsfiddle.net/inuitcss/Y2zrU
 * 
 */
.multi-list {
    @extend .cf;
    list-style: none;
    margin-left: 0;

    > li {
        float: left;
    }
}
    
/**
 * Apply these classes alongside the `.matrix` or `.multi-list` classes on
 * lists to determine how wide their columns are.
 */
.two-cols > li {
    width: 50%;
}

.three-cols > li {
    width: 33.333%;
}

.four-cols > li {
    width: 25%;
}

.five-cols > li {
    width: 20%;
}

/**
 * Unfortunately we have to qualify this selector in order to bring its
 * specificity above the `.[number]-cols > li` selectors above.
 */
.matrix > .all-cols,
.multi-list > .all-cols {
    width: 100%;
}
