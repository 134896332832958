/*------------------------------------*\
    $OPTIONS
\*------------------------------------*/
/**
 * Link-group nav, used for displaying related options. Extends `.nav--block`
 * but could also extend `.nav--fit`. Extend with colours and ‘current states’
 * in your theme stylesheet.
 * 
  <ul class="nav  options">
      <li><a></a></li><!-- 
   --><li><a></a></li><!-- 
   --><li><a></a></li><!-- 
   --><li><a></a></li> 
   </ul>
 * 
 * Demo: jsfiddle.net/inuitcss/vwfaf
 * 
 */
.options {
    display: block;
    font-size: 0;
    margin-bottom: 0;
}

.options__item {
    @include font-size($default-font-size, true);
    display: inline-block;
    white-space: nowrap;

    &:not(:first-child) {
        margin-left: -1px;
    }

    &:not(:first-child):not(:last-child) > .button {
        border-radius: 0;
    }

    &:first-child:not(:only-child) > .button {
        @include border-right-radius(0);
    }

    &:last-child:not(:only-child) > .button {
        @include border-left-radius(0);
    }

    &.current > .button {
        background: $brand-color;
        border-color: shade($brand-color, 10%);
        text-decoration: none;
    }

    > .button:hover,
    > .button:focus,
    > .button:active,
    &.current > .button {
        z-index: 1;
    }
}

.options__tab {
    button {
        @include betaBodyCopy();
        font-family: $univers-cond;
        display: inline-block;
        white-space: nowrap;
        border-bottom: solid 2px $shade-6-color;
        padding-top: $default-spacing-unit +px;
        padding-bottom: $default-spacing-unit +px;
        color: $shade-1-color;

        &:hover,
        &:focus,
        &:active {
            outline-width: 0;
            border-color: rgba($green, .5);
            background-color: rgba($green-2-light, .5);
        }
    }

    &.current {
        button {
            font-family: $univers-boldcond !important;
            line-height: $beta-body-copy-line-height - 1; // Accounts for Univers Bold Condensed height being 1 pixel taller tan Univers Normal Condensed
            border-color: $green;

            &:hover, 
            &:focus,
            &:active {
                outline-width: 0;
                background-color: $green-2-light;
            }
        }
        
    }

    &.disabled {
        button {
            color: $disabled-tab-color;
            border-color: $shade-6-color;       
            &:hover, 
            &:focus,
            &:active {
                outline-width: 0;
                background-color: $green-2-light;
            }
            &:disabled {
                color: $disabled-tab-color;
                border-color: $shade-6-color;
            }
        }
    }
}

/**
 * Stacked navigation that filters content rather than
 * linking somewhere.
 * 
   <ul class="filter-list">
       <li>
           <a href="#">Item</a>
       <li>
    </ul>
 *
 */

.filter-list__link {
    border-bottom: 0;
    border-radius: $brand-round +px;
    color: $default-text-color;
    display: block;
    font-weight: 500;
    padding: $half-spacing-unit +px;
    text-decoration: none;
    cursor: pointer;
}

.filter-list__link:hover {
    background: $shade-4-color;
}

.filter-list__link--current,
.filter-list__link--current:hover {
    @extend .weight--semibold;
    background: $brand-color;
}
