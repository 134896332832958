/*------------------------------------*\
    $COLUMNS
\*------------------------------------*/
/**
 * Here we can set elements in columns of text using CSS3, e.g.:
 *
   <p class=text-cols--2>
 *
 * Demo: jsfiddle.net/inuitcss/E26Yd
 *
 */

@mixin cols-setup($namespace: "") {
    .#{$namespace}text-cols--2    { column-count: 2; column-gap: $default-spacing-unit +px;}
    .#{$namespace}text-cols--3    { column-count: 3; column-gap: $default-spacing-unit +px;}
    .#{$namespace}text-cols--4    { column-count: 4; column-gap: $default-spacing-unit +px;}
    .#{$namespace}text-cols--5    { column-count: 5; column-gap: $default-spacing-unit +px;}
}

@include cols-setup();

@if $responsive == true {

    @include media-query(palm) {
        @include cols-setup("palm-");
    }

    @include media-query(lap) {
        @include cols-setup("lap-");
    }

    @include media-query(lap-and-up) {
        @include cols-setup("lap-and-up-");
    }

    @include media-query(portable) {
        @include cols-setup("portable-");
    }

    @include media-query(desk) {
        @include cols-setup("desk-");
    }
}

.text-col--break-before-avoid   { break-before: avoid-column; }
.text-col--break-after-avoid    { break-after: avoid-column; }
.text-col--break-inside-avoid   { break-inside: avoid-column; }