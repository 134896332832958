/*------------------------------------*\
    $LABELS
\*------------------------------------*/

div.label-rounded {
        border-radius: 500rem;
        padding: 6px!important;
        line-height: 1em;
        min-width: 2em;
        min-height: 2em;
}