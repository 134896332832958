.react-datepicker-wrapper {
  display: block !important;

  .error {
    color: $black;
  }
}

.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0px !important;
}

.react-datepicker-popper {
  z-index: 99;
}

.coyote {
  &.react-datepicker {
    background-color: $white;
    border-style: solid;
    border-width: 1px;
    font-size: $default-font-size + px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-top: none;
    padding: 2px 14px;

    .react-datepicker {
      &__header {
        background-color: $white;
        border: none;
      }

      &__day {
        line-height: 33px;
        color: black;
        width: 34px;
        height: 33px;

        &:hover {
          background-color: #bef2c2;
          border-radius: 50%;
        }

        &--keyboard-selected,
        &--selected {
          border-radius: 50%;
          background-color: #68c95c;
        }
      }

      &__current-month {
        color: $dark-grey;
        font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: bold;
      }

      &__day-name {
        color: #717171;
        font-weight: normal;
        width: 2rem;
        margin-left: 6px;
        margin-right: 6px;
        line-height: 14px;
      }

      &__day-names {
        padding-top: 12px;
      }

      &__day--today {
        font-weight: normal;
      }
    }

    .react-datepicker__navigation {
      background-position: center;
      border: none;
      height: 13px;
      top: 11px;
      width: 8px;

      &.react-datepicker__navigation--previous {
        margin-left: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='13' viewBox='0 0 8 13' %3E%3Cpath d='M7.19117551 11.53846156c.18024199.18024198.18024199.47247197 0 .65271395-.18024198.18024199-.47247197.18024199-.65271395 0L.67364305 6.326357 6.53846156.46153849c.18024198-.18024199.47247197-.18024199.65271395 0 .18024199.18024198.18024199.47247197 0 .65271395L1.97907095 6.326357l5.21210456 5.21210456z' fill='%23000'%3E%3C/path%3E%3C/svg%3E");
      }

      &.react-datepicker__navigation--next {
        margin-right: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='13' viewBox='0 0 8 13' %3E%3Cpath d='M.80882449 1.11425244c-.18024199-.18024198-.18024199-.47247197 0-.65271395.18024198-.18024199.47247197-.18024199.65271395 0L7.32635695 6.326357l-5.86481851 5.86481851c-.18024198.18024199-.47247197.18024199-.65271395 0-.18024199-.18024198-.18024199-.47247197 0-.65271395L6.02092905 6.326357.80882449 1.11425244z' fill='%23000'%3E%3C/path%3E%3C/svg%3E");
      }
    }

    .react-datepicker__day--disabled {
      opacity: 0.3;
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
    }
  }
}

.date-picker {
  height: 50px;
  padding: 2px 0px;

  .date-picker-label {
    position: absolute;
    font-size: 12px;
    top: 6px;
    padding: 9px 3px;
    left: 16px;
    transition: all 0.15s ease-in-out;
    -o-transition: all ease-in-out 0.15s;
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all ease-in-out 0.15s;
    pointer-events: none;
    color: $shade-1-color;
    background: transparent;
    font-weight: normal;
    margin: 0;
    line-height: 14px;
  }

  .date-picker-label.disabled {
    color: $shade-3-color;
  }

  .shrink {
    top: 0px;
    background: white;
    padding: 0px 5px;
    font-size: 10px;
  }

  input {
    width: 100%;
    height: 32px;
    border: 1px solid $shade-2-color;
    border-radius: 3px;
    padding: 15px;
    font-size: 12px !important;

    &:disabled {
      background: none;
      color: $shade-2-color;

      &:hover {
        border: 1px solid $shade-3-color !important;
      }
    }
  }

  input:hover {
    border: 1px solid $black;
  }

  input:focus {
    border: 1px solid $rfq-green;
    outline: none;
  }

  input.error {
    border: 1px solid $alert-red;
  }

  .datepicker-icon {
    position: relative;
    right: 10px;
    margin-top: -25px;
    float: right;
  }

  button:focus {
    outline: none;
  }

  label.error {
    color: $alert-red;
  }

  .validation-message {
    font-size: 10px;
    color: $alert-red;
    padding: 2px 0px 0px 14px;
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    background-color: $white !important;
    width: 33%;
    top: 25px;
    text-align: left;
    .react-datepicker__month-option--selected,
    .react-datepicker__year-option--selected {
      display: none;
    }
  }

  .react-datepicker__year-dropdown {
    width: 20%;
    left: 147px;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option {
    padding: 2px 12px;
    &:hover {
      background-color: #bef2c2;
    }
  }

  .react-datepicker__year-option--selected_year,
  .react-datepicker__month-option--selected_month,
  .react-datepicker__month--selected {
    background-color: $rfq-green;
  }

  .react-datepicker__month-read-view,
  .react-datepicker__year-read-view {
    visibility: visible !important;
  }

  .react-datepicker__navigation--years-upcoming,
  .react-datepicker__navigation--years-previous {
    display: none;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    top: 6px;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 5 18 11' %3E%3Cpath d='M16.818.703a.692.692 0 01.98.979L9 10.479.203 1.682a.692.692 0 01.979-.98L9 8.522 16.818.703z' fill='%23000'%3E%3C/path%3E%3C/svg%3E") !important;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    margin-left: 4px;
  }

  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    padding-left: 8px;
    font-weight: 700;
  }
}

.focus {
  border: 1px solid $rfq-green !important;
}
