/*------------------------------------*\
    $ALERT
\*------------------------------------*/
/**
 * Used to provide feedback or information.
 *
    <p class="alert alert--success" role="alert">
        <button type="button" class="alert__close-button">
            <i class="icon--clear" aria-label="Close"></i>
        </button>
    	<strong>Order saved.</strong>
    	Check your email 3 days prior to shipping for a special email from us.
    </p>
 *
 */
.alert {
    @extend .box--rounded-all;
    @extend .box--border-all;
    @extend [class*='box--border-'];
    padding: $default-spacing-unit +px;
    margin-bottom: $default-spacing-unit +px;

    /**
     * Headings inside of an alert should all
     * be the same size.
     */
    .alert__title {
        @extend .gamma;
        @extend .weight--semibold;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

/**
 * Alerts that can be dismissible with an X in the corner.
 */
.alert--dismissible {
    padding-right: $default-spacing-unit * 1.5 + px;

    .alert__close-button {
        @extend .text-link;
        @extend .muted;
        @extend .fr;
        position: relative;
        top: -$half-spacing-unit +px;
        right: -$default-spacing-unit +px;
    }
}

/**
 * All the different states for an alert.
 */
.alert--empty {
    @extend .box--white;
    text-align: center;
    color: $shade-2-color;
    border: 0;
}

.alert--success {
    @extend .box--green-light;
}

.alert--info {
    @extend .box--yellow-light;
}

.alert--warning {
    @extend .box--orange-light;
}

.alert--danger,
.alert--error {
    @extend .box--red-light;
}
.alert--center {
    @extend .box--brand-green;
    margin: 0 auto;
    border: none;
}
.alert--v2 {
    text-align: center;

    .carousel-banner-container, .carousel-banner-header {
        margin-top: -8px;
    }

    &.banner_success {
        border-color: #61AE66;

        .carousel-banner-title {
            font-size: 18px;
            color: #61AE66;
            font-family: UniversLTPro-55Roman;

            p {
                font-size: 13px;
                margin-top: -4px;
                color: #6D6D6D;
            }

            a {
                color: #3E9C44;
                text-decoration: none;
            }
        }
    }

    &.banner_info {
        border-color: #ffe30c;
        padding-bottom: 0px;

        .carousel-banner-title {
            font-size: 18px;
            color: #928941;
            font-family: UniversLTPro-55Roman;

            p {
                font-size: 13px;
                color: #303030;
            }
        }
    }
}
