.bid {
  .bar {
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }

  .complete {
    height: 24px;
    background-color: $rfq-green;
  }

  .incomplete {
    height: 24px;
    background-color: #ffffff;
  }

  .lost {
    height: 24px;
    background-color: $shade-3-color;
  }

  .rejected {
    height: 24px;
    background-color: $shade-6-color;
  }

  .start {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .end {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .border-grey-right {
    border-right: 1px solid #e7e7e7;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;

    .box-content {
      margin: 0;
    }
  }

  .message-input {
    border-radius: 4px;
    background-color: $site-background-color;
    outline: none;
    border: none;
    line-height: 18px;
    box-shadow: none;
    padding: 0px;
    min-height: 18px;
    margin-right: 2px;
  }

  .progress-bar {
    .border-point {
      position: relative;
    }

    .grey-bar {
      background-color: $shade-3-color;
      height: 24px;
    }

    .checkpoint {
      background-color: #e7e7e7;
      width: 1px;
      height: 40px;
      top: -16px;
      position: "absolute";
    }

    .progress-bar-error-icon {
      top: 2px;
    }
  }

  .percent-progress-container {
    width: 100%;
    height: 1.5em;
    position: relative;
    background-color: $shade-4-color;
    border-radius: 16px !important;

    .percent-progressbar {
      background-color: $rfq-green;
      height: 100%;
      position: absolute;
      line-height: inherit;
      border-radius: 16px !important;
    }
  }

  .dot {
    height: 9px;
    width: 9px;
    background-color: $shade-4-color;
    border-radius: 50%;
    display: inline-block;

    &--green {
      background-color: $rfq-green;
    }
  }

  .bid-owner {
    color: #262626;
    height: 14px;
    width: 62px;
    border-radius: 4px;
    background-color: $shade-4-color;
    text-align: center;
  }

  .messages {
    .panel-content {
      height: 424px;
    }
  }

  .messages .error {
    color: $rfq-red;
  }

  .messages .unread-message {
    height: 6px;
    width: 6px;
    background-color: $rfq-green;
    position: relative;
    top: 5px;
    right: 4px;
  }

  .messages .system-message .unread-message {
    position: absolute !important;
  }

  .grid-textarea-bg {
    background-color: $site-background-color;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  .team-inidicator {
    .refresh-button {
      height: 30px;
      margin-top: 11px;
    }
  }

  .no-results-found {
    letter-spacing: 4.8px;
  }

  .shipper-history {
    .header {
      display: inline-block !important;
      padding-right: 24px !important;
      line-height: 48px;
    }

    .box {
      height: 468px;
    }

    .status {
      height: 12px;
      width: 12px;
      border-radius: 9px;
      position: relative;
      top: 2px;
    }

    .header-cell {
      border-bottom: 0px;
      user-select: none;

      &:hover {
        background: $site-background-color;
      }
    }

    .row {
      height: 48px;
      &:hover {
        background-color: $coconut-white;
        cursor: pointer;
      }
    }

    .sorted-header {
      background: $site-background-color;
    }
  }
}

.bid-status-tag {
  vertical-align: super;
  border-radius: 20px;
  width: 80px;
  top: 2px;
}

.pricing {
  .react-tabs__tab-list {
    margin: 20px 0px 0px 12px;
  }

  .react-tabs__tab {
    padding: 12px 30px;
    margin: 0px;
    font-size: 14px;
    text-transform: none;
    background-color: $white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-right: 8px;
    width: 130px;
    text-align: center;
  }

  .react-tabs__tab--selected {
    background-color: $white;
    border-bottom: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: $univers-cond;
    text-transform: none;
    &::after {
      background-color: $picton-blue;
      height: 2px;
      bottom: 1px;
    }
  }

  .panel {
    background-color: #ffffff;
    position: relative;
    display: flex;
    height: 376px;
    justify-content: center;
    align-items: center;
  }

  .header-cell {
    border-bottom: 1px solid $silver;
    padding: 16px 0px 0px 0px;
    vertical-align: top;
    position: sticky;
    top: 0;
    background: #ffffff;
    white-space: nowrap;
    .header-div {
      border-bottom: 1px solid $light-grey-3;
      padding-left: 8px;
    }
    border-bottom: 0px;
  }

  .resizable {
    resize: horizontal;
    overflow: auto;
    margin: 0px;
    padding: 0px 16px 16px 0px;
    display: block;
    min-width: fit-content;
  }

  .header-cell .resizable:hover {
    background-image: url("../../images/ResizeIcon.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
  }

  .filter {
    .inline {
      z-index: 999;
      visibility: hidden;

      svg {
        float: none;
        padding-top: 2px;
      }
    }
  }

  .header-cell:hover,
  .header-cell.filtered {
    .inline {
      visibility: visible;
    }
  }

  .header-cell.medium {
    min-width: 72px;
  }

  .header-cell.tiny {
    min-width: 48px;
  }

  .header-cell.large {
    min-width: 120px;
  }

  .header-cell + .right {
    text-align: right;
  }

  .header-cell + .left {
    text-align: left;
  }

  .header-cell + .center {
    text-align: center;
  }

  .header-cell:not(.filter) {
    padding-top: 18px;
  }

  .loading-cell {
    border-bottom: 1px solid $light-silver;
    border-right: 1px solid $light-silver;
    border-top: 0px;
    padding: 11px 8px;

    span {
      height: 12px;
    }
  }

  .toolbar {
    padding: 8px 24px;

    .filter {
      width: 306px;
      border: 1px solid $shade-2-color;
      border-radius: 4px;
      outline: none;
      padding: 7px 14px;
    }
  }

  .row {
    height: 36px;
    .header-cell:first-child,
    .loading-cell:first-child {
      .header-div {
        padding-left: 24px;
        min-width: 72px;
      }
    }

    .pricing-cell:first-child {
      padding-left: 16px;
    }

    .simple-cell:first-child {
      padding-left: 24px;
    }

    .pricing-cell:last-child {
      padding-right: 16px;
    }

    .simple-cell:last-child {
      padding-right: 24px;
    }

    .button-cell {
      color: $blue;
    }

    .selected-button-cell {
      text-decoration: underline;
      font-weight: 700;
    }

    #matchCount {
      z-index: 1;
    }
    &.disabled {
      .simple-cell {
        color: $shade-2-color !important;
      }
    }
  }

  .highlight-row:not(.selected-row) {
    background-color: $site-background-color !important;

    input[type="text"] {
      background-color: $site-background-color !important;
      &:focus {
        background-color: $white !important;
      }
    }
  }

  .no-lanes-title {
    font-size: 34px;
    font-family: $univers-bold;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }

  .no-lanes-description {
    font-size: 18px;
    line-height: 21px;
    color: #616161;
    font-family: $font-stack;
  }

  .table-width {
    width: calc(100vw - 77px);
  }

  .pricing-cell {
    padding: 0px;
    border: 0px;

    input[type="text"] {
      box-shadow: none;
      width: 100%;
      outline: none;
      padding: 9px 8px;
      border-width: inherit;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus {
        border: 1px solid $rfq-green;
        background-color: #ffffff;
      }
    }

    border-right: 1px solid $silver;
    border-bottom: 1px solid $silver;
  }

  .simple-cell {
    padding: 4px 8px;
    border-width: 1px 0px 0px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    border-right: 1px solid $silver;
    border-bottom: 1px solid $silver;
    vertical-align: middle;
    border-top: 0px;

    .lane-match-count {
      border: 1px solid $rfq-green;
      border-radius: 50%;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .lane-match-selected {
      background-color: $rfq-green;
      font-weight: bold;
    }
    &.disabled {
      color: $shade-2-color;
    }

    .tag-cell {
      font-size: 12px;
      line-height: 16px;
      padding: 0px 8px;
      background-color: $light-yellow;
      display: block;
      border-radius: 100px;
      text-align: center;
    }

    .background-transparent {
      background-color: transparent;
    }
  }

  .scroll::-webkit-scrollbar-track {
    margin-top: 48px;
  }
  #pricing-grid,
  #lanes-match-grid,
  #analytics-grid {
    tbody {
      .row:first-child {
        .pricing-cell {
          input[type="text"]:focus {
            border-top: 1px solid $rfq-green;
          }
        }
      }
      tr:nth-child(even):not(.loading-row) {
        background-color: $shade-6-color;
        input[type="text"] {
          background-color: $shade-6-color;
        }
      }
    }
    .selected-cell {
      background-color: $site-background-color !important;
      input[type="text"] {
        background-color: $site-background-color !important;
        border: 0px !important;
      }
      input[type="text"]:focus {
        border: 0px !important;
      }
      + .disabled {
        color: $shade-2-color !important;
      }
    }
    .highlight-top {
      border-top: 1px solid $rfq-green;
    }
    .highlight-bottom {
      border-bottom: 1px solid $rfq-green;
    }
    .highlight-left {
      border-left: 1px solid $rfq-green;
    }
    .highlight-right {
      border-right: 1px solid $rfq-green;
    }
    .highlight-bottom-none {
      border-bottom: 0px;
    }
    .drop-indicator {
      border-left: 1px $shade-1-color solid;
    }
    thead th {
      border-right: 1px solid $silver;
    }
  }
  .selection-transparent {
    ::-moz-selection {
      background-color: transparent;
    }
    ::selection {
      background-color: transparent;
    }
  }
}

.table-width:hover .scroll::-webkit-scrollbar {
  display: block;
  margin-top: 48px;
}

.file-progress-bar {
  height: 8px;
  width: 100%;
  border-radius: 8px;
  background-color: $light-grey-1;
  margin-top: 4px;
}

.file-progress-complete {
  height: 8px;
  background-color: $rfq-green;
  border-radius: 8px;
}

.processing {
  display: block;
  background: linear-gradient(
    to right,
    $rfq-green 20%,
    #c8ebc7 50%,
    $rfq-green 80%
  );
  background-size: 500px 100px;
  animation: gradient 15s ease infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.details {
  width: 660px;
  margin: 0px auto;
}

.modal {
  .file-drop-zone {
    width: 493px;
  }
}

.assignments-table {
  td,
  th {
    padding: 4px;
    vertical-align: middle;
    text-align: right;
  }
  td:first-child,
  th:first-child {
    padding-left: 4px;
    text-align: left;
  }
}

.lane-assignment {
  .auto-complete {
    height: 40px;
  }
  .complete {
    height: 12px;
    border-radius: 6px;
    margin-top: 12px;
  }
}

.award-upload {
  .mode-selected {
    button {
      width: 48%;
      height: 75px;
      border: 1px solid #c1c1c1;

      &:focus {
        outline: none;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    span {
      color: $dark-grey;
      font-family: $font-stack;
    }

    .selected {
      border: 2px solid $rfq-green;
    }
  }
}

.remove-user {
  position: absolute;
  bottom: 40px;
  right: 0px;
  display: block;
  color: $blue;
  outline: none;
}

.bid-tab-list {
  margin-top: -13px;
}

.bid-title {
  max-width: 42%;
}

.bid-title-short-width {
  max-width: 22%;
}

.bid-name-error-icon {
  top: 2px;
}

.banner-bid-error {
  height: 100%;
  .error-icon {
    top: 2px;
  }
}
.lane-match {
  .filter-row {
    height: 44px;
    border-bottom: 1px solid $light-grey-3;
    .outlined-input {
      width: 100px;
      height: 40px !important;
    }
    .auto-complete {
      width: 112px;
    }
    .bid-status {
      .auto-complete {
        width: 124px;
      }
    }
    .checkbox {
      label {
        display: block;
        margin-top: 8px;
        position: relative;
        input[type="checkbox"] {
          position: absolute;
          margin-top: 1px;
        }
      }
    }
    .save {
      font-size: 14px;
      min-width: 116px;
    }
    .link-button {
      color: $blue;
    }
    .bid-status {
      .auto-complete__indicators {
        padding-top: 4px;
      }
    }
  }
  .lane-match-container {
    #lanes-match-grid {
      width: 99.99%;
      .resizable {
        padding: 0px 16px 9px 0px;
      }
      .header-cell {
        padding-top: 9px;
      }
      .simple-cell.disabled {
        color: $shade-1-color;
      }
    }
    .scroll::-webkit-scrollbar-track {
      margin-top: 36px;
    }
  }
  .panel {
    height: 293px;
  }

  .no-matches {
    letter-spacing: 4.8px;
  }

  .text-button:disabled {
    color: $med-grey-2;
  }
}

.summary,
.pricing,
.procurement {
  .scroll {
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background-color: $shade-3-color;
    }
  }
}

.delete-button {
  color: $red;
}

.bid-view .auto-complete {
  width: 180px;
  height: auto !important;
  top: 2px;
}

.bid-view .auto-complete__menu {
  z-index: 99;
}

.pricing,
#banner {
  .strategy {
    .date-picker {
      .date-picker-label {
        top: 2px;
        left: 12px;
      }

      .shrink {
        top: -3px;
      }

      input {
        border-radius: 0px;
      }
    }

    .grid__cell:nth-child(2) .date-picker input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .grid__cell:last-child .date-picker input {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
