.multi-select__control {
  .multi-select__multi-value {
    z-index: 9999;
    background-color: "#E6E6E6";
    .multi-select__multi-value__label {
      width: 75px;
      text-overflow: ellipsis;
    }
  }

  border: 1px solid $rfq-green !important;
  outline: 0px !important;
  .multi-select__value-container {
    @extend .scroll;
    overflow: auto !important;
    max-height: 150px !important;
  }
}

.multi-select__menu {
  position: relative !important;

  .multi-select__menu-list {
    @extend .scroll;
    max-height: 150px !important;
  }
  .multi-select__option--is-focused {
    background-color: #f5f6f5;
  }
}

.multi-select__multi-value {
  z-index: 9999;
}
